import React, { FunctionComponent } from "react";

import { IStyledDialog } from "../StyledDialog/interfaces/styledDialog";
import { StyledDialog } from "../StyledDialog";

type DialogMode = "cancel" | "confirm" | "info";

interface IAlertDialog extends IStyledDialog {
  onConfirm?: () => void;
  onCancel?: () => void;
  mode: DialogMode;
  cancelText?: string;
  confirmText?: string;
  hasForm?: boolean;
  dialogClassName?: string;
  confirmClassName?: string;
  cancelClassName?: string;
  variant?: "brown";
  customButtons?: JSX.Element[];
}

const AlertDialog: FunctionComponent<IAlertDialog> = (props) => {
  const {
    open,
    onCancel,
    onConfirm,
    title,
    message,
    mode,
    cancelText,
    confirmText,
    children,
    hasForm,
    dialogClassName,
    confirmClassName,
    cancelClassName,
    variant,
    customButtons,
  } = props;

  const confirmClassNames = `defaultButtons-confirm ${confirmClassName || ""}`;
  const closeClassNames = `defaultButtons-cancel ${cancelClassName || ""}`;

  let buttons; // mode === "info"
  if (mode === "info") {
    buttons = [
      <button
        className={confirmClassNames}
        onClick={onConfirm}
        key={1}
        type={hasForm ? "submit" : "button"}
      >
        {confirmText || "Confirm"}
      </button>,
    ];
  } else {
    buttons = [
      <button className={closeClassNames} onClick={onCancel} key={0}>
        {cancelText || "Cancel"}
      </button>,
      mode === "confirm" ? (
        <button
          className={confirmClassNames}
          onClick={onConfirm}
          key={1}
          type={hasForm ? "submit" : "button"}
        >
          {confirmText || "Confirm"}
        </button>
      ) : (
        <button
          className={confirmClassNames}
          onClick={onConfirm}
          key={2}
          type={hasForm ? "submit" : "button"}
        >
          {confirmText || "Confirm"}
        </button>
      ),
    ];
  }

  return (
    <StyledDialog
      className={dialogClassName}
      open={open}
      hasForm={hasForm}
      onClose={onCancel}
      title={title}
      message={message}
      buttons={customButtons || buttons}
      variant={variant}
    >
      {children}
    </StyledDialog>
  );
};
export default AlertDialog;
