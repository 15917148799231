import React, { useCallback, useState } from "react";
import classnames from "classnames";

import { currentMember } from "../../../mock";
import { IMessage } from "../../../interface";

import "./styles.scss";

interface DiscussionMessageItem {
  message: IMessage;
}

const DiscussionMessageItem: React.FC<DiscussionMessageItem> = ({
  message,
}) => {
  const { user, thread } = message;

  const [error, setError] = useState(false);

  const messages = useCallback(
    () =>
      thread.map((dummies, idx) => (
        <div className="message" key={idx}>
          {dummies.map((d, idx) => (
            <div
              key={idx}
              className="message-dummy"
              style={{ width: `${d}px` }}
            />
          ))}
        </div>
      )),
    [thread]
  );

  const isOwnerMessage = user.id === currentMember.id;

  const displayName = `${user.first_name} ${user.last_name}`;

  const image = user.image_url;

  const initials = `${user.first_name[0]} ${user.last_name[0]}`;

  return (
    <div
      className={classnames("discussionMessageItem", {
        "discussionMessageItem-owner": isOwnerMessage,
      })}
    >
      <div className="discussionMessageItem-container">
        <div className="discussionMessageItem-header">
          <div className="discussionMessageItem-avatar">
            {image && !error ? (
              <img
                src={image}
                alt={displayName}
                className="discussionMessageItem-avatar-image"
                onError={() => setError(true)}
              />
            ) : (
              <div className={classnames("custom-avatar-fallback")}>
                {initials}
              </div>
            )}
          </div>

          <p className="discussionMessageItem-header-userName">{displayName}</p>
          <p className="discussionMessageItem-header-date">{message.created}</p>
        </div>
        {messages()}
      </div>
    </div>
  );
};

export default DiscussionMessageItem;
