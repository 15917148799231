import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { DecisionCard } from "../../components";
import { EmailForm } from "../../components/DecisionContainer/EmailForm";
import { Header } from "../../components/EventContainer";
import { IEmailFormShape } from "../../interface";
import { sendDecisionMakerEmail } from "../../store/actions";
import config from "../../../../config";
import { Button } from "../../../../shared/components";

import "./styles.scss";

type FormType = "decision" | "creation";

const DecisionContainer: React.FC = () => {
  const [decisionEmail] = useState("");

  const dispatch = useDispatch();

  const handleSubmitEmail = (emailType: FormType) => (
    values: IEmailFormShape
  ) => {
    if (!values.email.trim()) {
      return;
    }
    if (emailType === "creation") {
      window.open(`${config.mainAppURL}/creation`, "_self");
    } else {
      dispatch(sendDecisionMakerEmail.request(values));
    }
  };

  return (
    <div className="decision">
      <Header />
      <div className="decision-wrapper">
        <DecisionCard title="Share “Why Altar” with your decision maker">
          <div className="decision-image-container">
            <img
              src="/images/decision/decision_send_email.png"
              alt="event images"
              className="send-email"
            />
            <img
              src="/images/decision/decision_send_email_mobile.png"
              alt="event images"
              className="send-email-mobile"
            />
          </div>
          <EmailForm
            title={
              <>
                <span className="email-title">
                  Where should we sent the summary PDF?
                </span>
                <span className="email-title-mobile">Email</span>
              </>
            }
            submitButtonText="send"
            formData={{ email: decisionEmail }}
            handleSubmit={handleSubmitEmail("decision")}
          />
          <div className="registration-mobile">
            <p>
              If you are a decision-maker,
              <br />
              check it out for yourself!
            </p>
            <div className="divider">
              <div>OR</div>
            </div>
            <h2 className="decision-card-title">Get your Community on Altar</h2>
            <Button
              type="button"
              radius={9}
              variant="orange"
              textTransform="none"
              className="create-community"
              onClick={() =>
                window.open(`${config.mainAppURL}/creation`, "_self")
              }
            >
              Create
            </Button>
          </div>
        </DecisionCard>
        <DecisionCard title="Get your Community on Altar">
          <div className="decision-image-container">
            <img
              src="/images/decision/decision_create_community.png"
              alt=" community images"
            />
          </div>
          <Button
            type="button"
            radius={9}
            variant="orange"
            textTransform="none"
            className="create-community"
            onClick={() =>
              window.open(`${config.mainAppURL}/creation`, "_self")
            }
          >
            Create
          </Button>
        </DecisionCard>
      </div>
    </div>
  );
};

export default DecisionContainer;
