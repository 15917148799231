import React from "react";
import classnames from "classnames";

import "./eventBadge.scss";

interface IEventBadge {
  text: string;
  iconSrc?: string;
  className?: string;
  onClick?: () => void;
}

const EventBadge: React.FC<IEventBadge> = ({
  text,
  iconSrc,
  className,
  onClick,
}) => {
  return (
    <div className={classnames("eventBadge", className)} onClick={onClick}>
      {iconSrc && (
        <div className="eventBadge-icon">
          <img src={iconSrc} alt="badge icon" />
        </div>
      )}
      <div className="eventBadge-text">{text}</div>
    </div>
  );
};
export default EventBadge;
