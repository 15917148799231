import {
  action,
  createAsyncAction,
  createStandardAction,
} from "typesafe-actions";

import { EventActionTypes } from "./constants";
import {
  EventStateType,
  ICreateEvent,
  IEmailFormShape,
  IEvent,
  IEventSettings,
  IRoom,
  IUpdateEvent,
} from "../interface";
import { IUser } from "../../Auth/interface/User";

export const getEventByCode = createAsyncAction(
  EventActionTypes.GET_BY_CODE,
  EventActionTypes.GET_BY_CODE_SUCCESS,
  EventActionTypes.GET_BY_CODE_FAILURE
)<string, IEvent, Error>();

export const createEvent = createAsyncAction(
  EventActionTypes.CREATE,
  EventActionTypes.CREATE_SUCCESS,
  EventActionTypes.CREATE_FAILURE
)<ICreateEvent, IEvent, Error>();

export const updateEvent = createAsyncAction(
  EventActionTypes.UPDATE,
  EventActionTypes.UPDATE_SUCCESS,
  EventActionTypes.UPDATE_FAILURE
)<IUpdateEvent, IEvent, Error>();

export const clearCurrentEvent = createStandardAction(
  EventActionTypes.CLEAR_EVENT
)<undefined>();

export const updateRooms = createAsyncAction(
  EventActionTypes.UPDATE_ROOMS,
  EventActionTypes.UPDATE_ROOMS_SUCCESS,
  EventActionTypes.UPDATE_ROOMS_FAILURE
)<number, IRoom[], Error>();

export const joinEvent = createAsyncAction(
  EventActionTypes.JOIN_EVENT,
  EventActionTypes.JOIN_EVENT_SUCCESS,
  EventActionTypes.JOIN_EVENT_FAILURE
)<number, undefined, Error>();

export const sendDecisionMakerEmail = createAsyncAction(
  EventActionTypes.SEND_DECISION_MAKER_EMAIL,
  EventActionTypes.SEND_DECISION_MAKER_EMAIL_SUCCESS,
  EventActionTypes.SEND_DECISION_MAKER_EMAIL_FAILED
)<IEmailFormShape, undefined, Error>();

export const setRoomCode = createStandardAction(EventActionTypes.SET_ROOM_CODE)<
  EventStateType["connections"]["roomCode"]
>();

export const getUserMedia = createAsyncAction(
  EventActionTypes.GET_USER_MEDIA,
  EventActionTypes.GET_USER_MEDIA_SUCCESS,
  EventActionTypes.GET_USER_MEDIA_FAILURE
)<MediaStreamConstraints, MediaStream, Error>();

export const connectSocket = createAsyncAction(
  EventActionTypes.CONNECT_SOCKET,
  EventActionTypes.CONNECT_SOCKET_SUCCESS,
  EventActionTypes.CONNECT_SOCKET_FAILURE
)<
  { meeting_id: number | null; room_code: string | null },
  EventStateType["connections"]["roomCode"],
  Error
>();

export const updateEventSettings = createStandardAction(
  EventActionTypes.UPDATE_EVENT_SETTINGS
)<Partial<IEventSettings>>();
export const clearEventSettings = createStandardAction(
  EventActionTypes.CLEAR_EVENT_SETTINGS
)();

export const addStream = createStandardAction(EventActionTypes.ADD_STREAM)<{
  clientId: string;
  stream: MediaStream;
  user: IUser;
}>();

export const removeStream = createStandardAction(
  EventActionTypes.REMOVE_STREAM
)<MediaStream["id"]>();

export const startEvent = (eventId: number) =>
  action(EventActionTypes.START_EVENT, eventId);

export const connectEventSocket = () =>
  action(EventActionTypes.CONNECT_EVENT_SOCKET);

export const updateStateEvent = createStandardAction(
  EventActionTypes.WS_EVENT_UPDATED
)<Partial<IEvent>>();

export const exitRoom = createAsyncAction(
  EventActionTypes.EXIT_ROOM,
  EventActionTypes.EXIT_ROOM_SUCCESS,
  EventActionTypes.EXIT_ROOM_FAILURE
)<undefined, undefined, Error>();
