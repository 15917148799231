import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { Seat } from "../Seat";
import { IRoom } from "../../../interface";
import { IMember } from "../../../../Member/interface";
import { getEvent } from "../../../store/selectors";

import "./styles.scss";

type Props = {
  bench: IRoom;
};

const Bench: React.FC<Props> = ({ bench }) => {
  const history = useHistory();

  const currentEvent = useSelector(getEvent());

  const attendees = useMemo<(IMember | null)[]>(() => {
    const { members } = bench;
    const attendees: (IMember | null)[] = [...members];
    while (attendees.length < 4) {
      attendees.push(null);
    }
    return attendees;
  }, [bench]);

  const navigateToRoom = () => {
    history.push(`/${currentEvent?.code}/watchparty/${bench.code}`);
  };

  return (
    <div className="bench">
      {attendees.map((member, i) => (
        <Seat
          key={i}
          attendee={member}
          onClick={bench.code && i === 3 ? navigateToRoom : undefined}
          className={bench.code && i === 3 ? "available" : undefined}
          available={!!(bench.code && i === 3)}
        />
      ))}
    </div>
  );
};

export default Bench;
