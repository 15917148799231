import { call, put, takeLatest, select, race, take } from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import io, { Socket } from "socket.io-client";

import api from "../api";
import { EventActionTypes } from "./constants";
import { startLoading, stopLoading } from "../../../shared/store/actions";
import {
  connectEventSocket,
  connectSocket,
  createEvent,
  exitRoom,
  getEventByCode,
  getUserMedia,
  joinEvent,
  startEvent,
  updateEvent,
  updateRooms,
  updateStateEvent,
} from "./actions";
import { ICreateEvent, IEmailFormShape, IUpdateEvent } from "../interface";
import { Action } from "../../../shared/interfaces/Redux";
import { peerConnection } from "../utils";
import { getConnections } from "./selectors";
import config from "../../../config";
import { notificationActions } from "../../Notifications/store/actions";

function* getByCodeSaga({ payload }: Action<string>) {
  try {
    yield put(startLoading());
    const events = yield call(api.get, payload);
    yield put(getEventByCode.success(events));
  } catch (error) {
    yield put(getEventByCode.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* createSaga({ payload }: Action<ICreateEvent>) {
  try {
    yield put(startLoading());
    const newEvent = yield call(api.createSermon, payload);
    yield put(createEvent.success(newEvent));
  } catch (error) {
    yield put(createEvent.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* updateSaga({ payload: { id, status } }: Action<IUpdateEvent>) {
  try {
    yield put(startLoading());
    const updatedEvent = yield call(api.update, id, { status });
    yield put(updateEvent.success(updatedEvent));
  } catch (error) {
    yield put(updateEvent.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

export function* updateRoomsSaga(
  action: ReturnType<typeof updateRooms.request>
) {
  try {
    peerConnection.getRooms(action.payload);
  } catch (e) {
    yield put(updateRooms.failure(e));
  }
}

export function* joinEventSaga(action: ReturnType<typeof joinEvent.request>) {
  try {
    peerConnection.joinMeeting(action.payload);
  } catch (e) {
    yield put(joinEvent.failure(e));
  }
}

// Can not work getUserMedia in yield call
async function callGetUserMedia(
  constraints: MediaStreamConstraints
): Promise<Error | MediaStream> {
  try {
    return await navigator.mediaDevices.getUserMedia(constraints);
  } catch (e) {
    throw new Error(e);
  }
}

export function* getUserMediaSaga(
  action: ReturnType<typeof getUserMedia.request>
) {
  try {
    const stream = yield call(callGetUserMedia, action.payload);

    yield put(getUserMedia.success(stream as MediaStream));
  } catch (e) {
    yield put(getUserMedia.failure(e));
  }
}

export function* connectSocketSaga(
  action: ReturnType<typeof connectSocket.request>
) {
  try {
    const state: any = yield select(getConnections());

    if (!state.stream) {
      throw new Error("Could not find Stream");
    }

    const roomCode = yield call(
      peerConnection.connectSocket,
      state.stream,
      action.payload.room_code,
      action.payload.meeting_id
    );

    if (!roomCode) {
      throw new Error("Cannot create room");
    }

    yield put(connectSocket.success(roomCode as string));
  } catch (e) {
    yield put(connectSocket.failure(e));
  }
}

function createSocket(eventId: number) {
  return new Promise((resolve, reject) => {
    const socket = io(`${config.wsApiUrl}/${eventId}`, {
      path: config.wsPath,
      transports: ["websocket", "pooling"],
    });
    socket.on("connect_error", reject);

    socket.on("connect", () => {
      const message = "[BROWSER] socket.io: Socket was connected";

      // tslint:disable-next-line:no-console
      console.log(message);
      let missingHeartBeats = 0;
      let needDisconnect = true;
      socket.on("heartbeat", () => {
        missingHeartBeats = 0;
      });

      const heartBeatInterval = setInterval(() => {
        if (missingHeartBeats > 1) {
          const message =
            "[BROWSER] socket.io: Heartbeat wasn't received from the server. Disconnecting...";

          // tslint:disable-next-line:no-console
          console.log(message);
          needDisconnect = false;
          socket.disconnect();
        }
        missingHeartBeats += 1;
        socket.emit("heartbeat");
      }, 3000);

      socket.on("disconnect", () => {
        if (needDisconnect) {
          const message = "[BROWSER] socket.io: Socket was disconnected";
          // tslint:disable-next-line:no-console
          console.log(message);
          return clearInterval(heartBeatInterval);
        }
        const message =
          "[BROWSER] socket.io: Socket was disconnected. Trying to reconnect...";
        // tslint:disable-next-line:no-console
        console.log(message);
        clearInterval(heartBeatInterval);
        socket.connect();
      });

      resolve(socket);
    });
  });
}

function createSocketChannel(socket: typeof Socket) {
  return eventChannel((emit) => {
    const handler = (method: string, ...args: any) => {
      emit({
        method,
        args,
      });
    };

    socket.on("message", handler);

    socket.on("reconnect_failed", () => {
      emit(END);
    });

    return () => {
      socket.off("message", handler);
      socket.close();
    };
  });
}

interface IWSAction {
  method: string;
  args: any[];
}

function* startEventSaga(action: ReturnType<typeof startEvent>) {
  let socket: SocketIOClient.Socket;
  let socketChannel: any;
  try {
    socket = yield call(createSocket, action.payload);
    yield put(connectEventSocket());
    socketChannel = yield call(createSocketChannel, socket);
    while (true) {
      const [income, close] = yield race([
        take(socketChannel),
        take(EventActionTypes.CLOSE_SOCKET),
      ]);

      if (income) {
        const { method, args } = income as IWSAction;
        switch (method) {
          case "eventUpdated": {
            yield put(updateStateEvent(args[0]));
            break;
          }
          default:
            // tslint:disable-next-line:no-console
            console.warn(`Unknown event ${method}`);
        }
      } else if (close) {
        socketChannel.close();
        break;
      }
    }
  } catch (error) {
    const message = `[BROWSER] socket.io: ${error}`;
    // tslint:disable-next-line:no-console
    console.log(message);
  }
}

function* sendDecisionMakerSaga({ payload }: Action<IEmailFormShape>) {
  try {
    yield put(startLoading());
    yield call(api.sendDecisionMaker, payload);
    yield put(notificationActions.success("Email was successfully sent"));
  } catch (error) {
    yield put(
      notificationActions.error(
        "Cannot send email",
        (error && error.message) || ""
      )
    );
  } finally {
    yield put(stopLoading());
  }
}

export function* exitRoomSaga() {
  try {
    const { stream }: any = yield select(getConnections());

    if (stream) {
      stream.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
    }

    peerConnection.exit();
    yield put(exitRoom.success());
  } catch (e) {
    yield put(exitRoom.failure(e));
  }
}

function* eventSagas() {
  yield takeLatest(EventActionTypes.GET_BY_CODE, getByCodeSaga);
  yield takeLatest(EventActionTypes.CREATE, createSaga);
  yield takeLatest(EventActionTypes.UPDATE, updateSaga);
  yield takeLatest(EventActionTypes.UPDATE_ROOMS, updateRoomsSaga);
  yield takeLatest(EventActionTypes.JOIN_EVENT, joinEventSaga);
  yield takeLatest(EventActionTypes.GET_USER_MEDIA, getUserMediaSaga);
  yield takeLatest(EventActionTypes.CONNECT_SOCKET, connectSocketSaga);
  yield takeLatest(EventActionTypes.START_EVENT, startEventSaga);
  yield takeLatest(
    EventActionTypes.SEND_DECISION_MAKER_EMAIL,
    sendDecisionMakerSaga
  );
  yield takeLatest(EventActionTypes.EXIT_ROOM, exitRoomSaga);
}

export default eventSagas;
