import React, { useEffect, FunctionComponent } from "react";
import { Grid } from "@material-ui/core";

import "./StyledDialog.scss";
import { IStyledDialog } from "./interfaces/styledDialog";
const ESCAPE_KEYS = ["27", "Escape"];

const StyledDialog: FunctionComponent<IStyledDialog> = (props) => {
  const {
    open,
    onClose,
    title,
    message,
    buttons,
    children,
    hasForm,
    className,
    variant,
  } = props;

  useEffect(() => {
    const escFunction = (e: any) => {
      if (ESCAPE_KEYS.includes(String(e.keyCode))) {
        onClose && onClose();
      }
    };

    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [onClose]);
  return (
    <React.Fragment>
      {open && (
        <div
          className={`styledDialog ${variant ? `variant-${variant}` : ""}`}
          onClick={(e) => {
            if (!hasForm) {
              e.preventDefault();
              e.stopPropagation();
              onClose && onClose();
            }
          }}
        >
          <div
            className={`styledDialog-body ${className || ""}`}
            style={props.style}
            onClick={(e) => e.stopPropagation()}
          >
            <Grid
              container
              className="styledDialog-container"
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              <Grid
                item
                xs
                className={`styledDialog-text ${
                  !!children ? "dialog-children" : ""
                }`}
              >
                <div className="styledDialog-text-title">{title}</div>
                <div className="styledDialog-text-content">{message}</div>
              </Grid>
              {children}
              <Grid item className="defaultButtons">
                {buttons &&
                  buttons.map((button, id) => (
                    <Grid key={id} item className="defaultButtons-button">
                      {button}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default StyledDialog;
