import { Configuration, ConfigurationObject } from "./shared/interfaces";
const { REACT_APP_ENV = "dev" } = process.env;

const dev: Configuration = {
  env: REACT_APP_ENV,
  applicationUrl: "http://localhost:3000",
  baseApiUrl: "http://localhost:4000/api/v1/",
  wsApiUrl: "http://localhost:4000",
  mainAppURL: "https://app.qa.altarlive.com",
  webSiteUrl: "https://www.altarlive.com",
  wsPath: "/rtc",
  turnServer: {
    url: "3.82.125.80",
    port: "3478",
    username: "923digital",
    password: "v5vXH4pd8EWDqjgc",
  },
};

const stage: Configuration = {
  ...dev,
};

const production: Configuration = {
  ...dev,
  applicationUrl: "https://demo.altarlive.com",
  baseApiUrl: "https://demo-api.altarlive.com/api/v1/",
  wsApiUrl: "https://demo-api.altarlive.com",
  mainAppURL: "https://app.altarlive.com",
  googleAnalyticsId: "UA-138633989-10",
};

const config: ConfigurationObject = {
  dev,
  stage,
  production,
};

const configElement: Configuration = config[REACT_APP_ENV];
export default configElement;
