import React, { ReactNode } from "react";

import { Button } from "../../../../../shared/components";
import {
  StyledArch,
  StyledCircle,
  StyledPolygon,
  StyledSquare,
  StyledSquarePolygon,
} from "../../../../../shared/components/Figures";

import "./styles.scss";

type Props = {
  title: ReactNode;
  description: ReactNode;
};

const Blocker: React.FC<Props> = ({ title, description }) => {
  const handleButtonClick = () => {
    window.location.replace("/?initial=1");
  };

  return (
    <>
      <div className="blocker-figures">
        <StyledCircle className="blocker-figures-circle" />
        <StyledSquarePolygon className="blocker-figures-squarePolygon" />
        <StyledPolygon className="blocker-figures-polygon" fill="#7D745A" />
        <StyledPolygon className="blocker-figures-polygon small" />
        <StyledArch className="blocker-figures-arch" />
        <StyledArch className="blocker-figures-arch small" fill="#F0B48F" />
        <StyledSquare className="blocker-figures-square" />
      </div>
      <div className="blocker">
        <div className="blocker-title">{title}</div>
        <div className="blocker-description">{description}</div>
        <Button variant="orange" onClick={handleButtonClick} className="start">
          Start your own demo
        </Button>
      </div>
    </>
  );
};
export default Blocker;
