import React, { useCallback, useEffect, useMemo } from "react";
import { Switch, Route, useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  SeatsContainer,
  StreamContainer,
  LobbyContainer,
  TableContainer,
} from "../";
import {
  getEventByCode,
  joinEvent,
  updateRooms,
  updateEventSettings,
  startEvent,
} from "../../store/actions";
import {
  Header,
  Footer,
  EventDashboard,
  EventMembersDialog,
  EventDiscussions,
} from "../../components/EventContainer";
import {
  getConnections,
  getEvent,
  getEventSettings,
} from "../../store/selectors";
import { getUserData, login, logout } from "../../../Auth/store/actions";
import { users } from "../../../App/constants";
import { authentificated, getUser } from "../../../Auth/store/selectors";
import { EventDashboardTabs } from "../../interface";
import { isMatchedPath } from "../../../../shared/utils/location";
import { EEventStatus } from "../../interface";
import { isUserHost } from "../../utils/event";
import { notificationActions } from "../../../Notifications/store/actions";
import { searchParamsToObject } from "../../../../shared/utils/searchParams";

import "./styles.scss";

const EventContainer: React.FC = () => {
  const { eventCode } = useParams<{ eventCode?: string }>();

  const dispatch = useDispatch();
  const history = useHistory();

  const currentEvent = useSelector(getEvent());
  const is_authorized = useSelector(authentificated());
  const currentUser = useSelector(getUser());
  const { roomCode } = useSelector(getConnections());
  const { activeDashboardTab } = useSelector(getEventSettings());

  const isHost = useMemo(() => isUserHost(currentUser, currentEvent), [
    currentEvent,
    currentUser,
  ]);

  const { initial } = useMemo(
    () => searchParamsToObject(history.location.search),
    [history.location]
  );

  useEffect(() => {
    if (initial && is_authorized) {
      dispatch(logout.request());
    } else if (!is_authorized) {
      dispatch(login.request(users[eventCode ? 1 : 0]));
    }
    history.replace({ search: "" });
  }, [dispatch, eventCode, history, initial, is_authorized]);

  useEffect(() => {
    if (!currentUser) {
      dispatch(getUserData.request());
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (eventCode && !currentEvent && is_authorized) {
      dispatch(getEventByCode.request(eventCode));
    }
  }, [currentEvent, dispatch, eventCode, is_authorized]);

  useEffect(() => {
    if (currentEvent?.id) {
      dispatch(startEvent(currentEvent.id));
      dispatch(joinEvent.request(currentEvent.id));
      dispatch(updateRooms.request(currentEvent.id));
    }
  }, [dispatch, currentEvent]);

  useEffect(() => {
    if (currentEvent) {
      let redirectTo = "";
      switch (currentEvent.status) {
        case EEventStatus.live: {
          if (roomCode) {
            const notWatchParty = isMatchedPath(history.location.pathname, [
              "/",
              "/:eventCode/lobby",
              "/:eventCode/lobby/:roomCode",
            ]);
            if (notWatchParty) {
              redirectTo = `/${currentEvent.code}/watchparty/${roomCode}`;
            }
          }
          break;
        }
        case EEventStatus.lobby: {
          const notLobby = isMatchedPath(history.location.pathname, [
            "/",
            "/:eventCode/watchparty/:roomCode",
          ]);
          if (notLobby) {
            if (!isHost && roomCode) {
              redirectTo = `/${currentEvent.code}/lobby/${roomCode}`;
              dispatch(
                notificationActions.info(
                  " ",
                  "Host has switched the session to breakout tables"
                )
              );
            } else {
              redirectTo = `/${currentEvent.code}/lobby`;
            }
          }
          break;
        }
        case EEventStatus.ended: {
          const notDecision = !isMatchedPath(history.location.pathname, [
            "/decision",
          ]);
          if (notDecision) {
            if (isHost) {
              redirectTo = `/decision`;
            } else {
              redirectTo = `/ended`;
            }
          }
          break;
        }
        default:
          return;
      }
      if (redirectTo) {
        history.push(redirectTo);
      }
    }
  }, [eventCode, roomCode, history, dispatch, currentEvent, isHost]);

  const handleChangeActiveTab = useCallback(
    (tab: EventDashboardTabs | null) => {
      dispatch(updateEventSettings({ activeDashboardTab: tab }));
    },
    [dispatch]
  );

  const handleCloseTab = useCallback(() => {
    dispatch(updateEventSettings({ activeDashboardTab: null }));
  }, [dispatch]);

  return (
    <div className="event-container">
      <Header />
      <div className="event-container-wrapper">
        <div className="event-container-body">
          <div className="event-container-content">
            <Switch>
              <Route path={"/"} exact component={SeatsContainer} />
              <Route
                path={"/:eventCode/watchparty/:roomCode"}
                exact
                component={StreamContainer}
              />
              <Route
                path={"/:eventCode/lobby"}
                exact
                component={LobbyContainer}
              />
              <Route
                path={"/:eventCode/lobby/:roomCode"}
                exact
                component={TableContainer}
              />
            </Switch>
            {activeDashboardTab === EventDashboardTabs.members && (
              <EventMembersDialog
                onClose={handleCloseTab}
                open={activeDashboardTab === EventDashboardTabs.members}
              />
            )}
            {activeDashboardTab === EventDashboardTabs.chat && (
              <EventDiscussions
                onClose={handleCloseTab}
                open={activeDashboardTab === EventDashboardTabs.chat}
              />
            )}
          </div>
          <Footer />
        </div>
        <EventDashboard
          activeTab={activeDashboardTab}
          onChange={handleChangeActiveTab}
        />
      </div>
    </div>
  );
};

export default EventContainer;
