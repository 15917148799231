import React, { useEffect, useState } from "react";
import classnames from "classnames";

import "./styles.scss";

type ArrowVariant = "bottom_left" | "bottom_right" | "top_right";

interface IPrompt {
  text: string | JSX.Element;
  arrowVariant: ArrowVariant;
  className?: string;
  showDelay?: number;
  hide?: boolean;
}

const Prompt: React.FC<IPrompt> = ({
  text,
  arrowVariant,
  className,
  showDelay = 0,
  hide,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => setShow(true), showDelay);
  }, [showDelay]);
  return show ? (
    <div className={classnames("prompt", className, { "prompt-hidden": hide })}>
      <div className="prompt-text">{text}</div>
      <div className="prompt-arrow">
        <img src={`/images/prompt/${arrowVariant}.png`} alt="prompt" />
      </div>
    </div>
  ) : null;
};

export default Prompt;
