import React, { useCallback, useEffect } from "react";

import { BenchesList } from "../../components/SeatsContainer/BenchesList";
import { createEvent } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  EEventRestriction,
  EEventStatus,
  EEventStreamType,
  EEventType,
} from "../../interface";
import { communityId, streamUrl } from "../../../App/constants";
import { getEvent, getRooms } from "../../store/selectors";
import { getUser } from "../../../Auth/store/selectors";
import CountdownTimer from "../../../../shared/components/CountdownTimer";

import "./styles.scss";

const SeatsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector(getUser());
  const currentEvent = useSelector(getEvent());
  const realRooms = useSelector(getRooms());

  const member = currentUser ? currentUser.members[0] : null;

  useEffect(() => {
    if (!currentEvent && member?.id) {
      const endDate = new Date();
      endDate.setHours(endDate.getHours() + 1);
      dispatch(
        createEvent.request({
          name: "Test Event",
          subject: "",
          startTime: new Date().toISOString(),
          endTime: endDate.toISOString(),
          setup_time: 0,
          post_event_time: 0,
          restriction: EEventRestriction.all,
          attendees: [],
          presenters: [],
          greeters: [],
          host: member.id,
          presentersCodes: [],
          type: EEventType.stream,
          status: EEventStatus.live,
          stream_type: EEventStreamType.YOUTUBE,
          stream_url: streamUrl,
          communityId,
        })
      );
    }
  }, [currentEvent, dispatch, member]);

  const navigateToRoom = useCallback(() => {
    if (realRooms.length > 0) {
      history.push(`/${currentEvent?.code}/watchparty/${realRooms[0].code}`);
    }
  }, [currentEvent, history, realRooms]);

  return (
    <div className="seats-container">
      <div className="seats-container-scene">
        <div className="seats-container-scene-timer">
          <p className="seats-container-scene-timer-label">
            <span>Live Event will start soon,</span>{" "}
            <span>please grab Your Seat!</span>
          </p>
          <CountdownTimer initTime={10100} onTimeHasCome={navigateToRoom} />
        </div>
      </div>
      <div className="seats-container-benches">
        <BenchesList />
      </div>
    </div>
  );
};

export default SeatsContainer;
