import React, { RefObject, useMemo } from "react";
import { useHistory } from "react-router";
import { Prompt } from "../../../../../shared/components";
import classnames from "classnames";

import { IMember } from "../../../../Member/interface";
import { Seat } from "../../SeatsContainer/Seat";

import "./index.scss";

interface TableItemProps {
  attendees?: (IMember | null)[];
  table_id: string;
  reference?: RefObject<any>;
  className?: string;
}

export interface AttendeeClassMatrixInterface {
  [key: number]: string;
}

const AttendeeClassMatrix: AttendeeClassMatrixInterface = {
  0: "top",
  1: "right",
  2: "bottom",
  3: "left",
};

const TableItem: React.FC<TableItemProps> = ({
  attendees = [],
  table_id,
  reference,
  className,
}) => {
  const history = useHistory();
  const participants = useMemo<(IMember | null)[]>(() => {
    const participants: (IMember | null)[] = [...attendees];
    while (participants.length < 4) {
      participants.push(null);
    }
    return participants;
  }, [attendees]);

  const navigateToRoom = () => {
    history.push(`${window.location.pathname}/${table_id}`);
  };

  const full = attendees.filter(Boolean).length >= 4;

  return (
    <div
      className={classnames("table-item-wrapper", className)}
      ref={reference}
    >
      <div className="table-content">
        <div className="attendee-list">
          {participants.map((att, index) => (
            <Seat
              key={att ? att?.id : index}
              attendee={att}
              className={classnames(AttendeeClassMatrix[index], {
                available: table_id && index === 3,
              })}
            />
          ))}
          {!!table_id && (
            <Prompt
              className="attendee-list-prompt"
              text="Join this table"
              arrowVariant="bottom_right"
              showDelay={1000}
            />
          )}
        </div>
        <div className="table">
          <div className="button-wrapper">
            <button
              disabled={full}
              onClick={table_id ? navigateToRoom : undefined}
            >
              {full ? "FULL" : "JOIN"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableItem;
