import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useElementSize } from "../../../../../shared/hooks/ElementSizeHook";
import { EEventStatus, EEventStreamType, IEvent } from "../../../interface";
import { updateEvent } from "../../../store/actions";
import { getEvent } from "../../../store/selectors";
import { calcPlayerSize, parseIframeProps } from "../../../utils/event";

import "./styles.scss";

type Props = {
  event: IEvent;
  canPlay?: boolean;
};

const Stream: React.FC<Props> = ({ event, canPlay }) => {
  const [ref, setRef] = useState(false);
  const [customIframeProps, setCustomIframeProps] = useState<
    Record<string, string>
  >({});

  const currentEvent = useSelector(getEvent());

  const dispatch = useDispatch();

  const videoContainerRef = useRef<HTMLDivElement>(null);
  const { height: containerHeight, width: containerWith } = useElementSize(
    videoContainerRef.current
  );

  const onEndVideoHandler = useCallback(() => {
    if (currentEvent?.id) {
      dispatch(
        updateEvent.request({
          status: EEventStatus.lobby,
          id: currentEvent.id,
        })
      );
    }
  }, [dispatch, currentEvent]);

  useEffect(() => {
    if (videoContainerRef.current) {
      setRef(true);
    }
  }, [ref, event]);

  const playerSize =
    event.stream_type === EEventStreamType.FB &&
    !!containerHeight &&
    !!containerWith &&
    calcPlayerSize(
      containerWith,
      containerHeight,
      videoContainerRef.current?.querySelector("iframe")
    );

  useEffect(() => {
    if (
      event?.stream_url &&
      Object.keys(customIframeProps).length === 0 &&
      event.stream_type === EEventStreamType.CUSTOM
    ) {
      setCustomIframeProps(parseIframeProps(event.stream_url));
    }
  }, [customIframeProps, event]);

  return (
    <div className="stream" ref={videoContainerRef}>
      <div className="stream-video">
        {event.stream_type === EEventStreamType.CUSTOM ? (
          <iframe
            title="video-stream"
            {...customIframeProps}
            height="100%"
            width="100%"
          />
        ) : (
          canPlay && (
            <video
              autoPlay
              onEnded={onEndVideoHandler}
              controls={false}
              playsInline
              width={playerSize ? `${playerSize.width}px` : "100%"}
              height={playerSize ? `${playerSize.hight}px` : "100%"}
            >
              <source src="https://church-application.s3.amazonaws.com/demo-files/watch-party-demo-church.mp4" />
            </video>
          )
        )}
      </div>
    </div>
  );
};

export default Stream;
