import { convertIframeProp } from "../constants/event";
import config from "../../../config";
import { IUser } from "../../Auth/interface/User";
import { IEvent } from "../interface";

export const calcPlayerSize = (
  containerWidth: number,
  containerHeight: number,
  iframe?: HTMLIFrameElement | null
) => {
  let playerHeight: number;
  let playerWidth: number;

  if (!iframe) return null;

  const aspectRatio = iframe.offsetWidth / iframe.offsetHeight;

  if (containerWidth / aspectRatio > containerHeight) {
    playerHeight = containerHeight;
    playerWidth = containerHeight * aspectRatio;
  } else {
    playerWidth = containerWidth;
    playerHeight = containerWidth / aspectRatio;
  }
  return { hight: playerHeight, width: playerWidth };
};

export const parseIframeProps = (iframe: string) => {
  const props: Record<string, any> = {};
  const match = iframe.match(/^\s*<iframe\s*([^>]*)>/);
  if (Array.isArray(match) && match.length > 1) {
    let [, params] = match;
    params = params.replace(/\w*="[^"]*"/g, (row) => {
      const [key, ...vals] = row.split("=");
      let val = vals.join("=").replaceAll('"', "");
      if (key === "src") {
        // for twitch videos
        val = val.replace(
          "www.example.com",
          config.applicationUrl.replace(/http(s)?:\/\//, "")
        );
      }
      props[convertIframeProp(key)] = val;

      return "";
    });
    params
      .trim()
      .split(/\s+/)
      .forEach((key) => {
        props[convertIframeProp(key)] = true;
      });
  }
  delete props.style;

  return props;
};

export const isUserHost = (
  user: IUser | null,
  event: IEvent | null
): boolean => {
  if (user && event) {
    const [member] = user.members;
    return member ? member.id === event.host_id : false;
  }
  return false;
};
