import React from "react";
import Lottie from "react-lottie";

import animationData from "./AltarLoader.json";

import "./loader.scss";

const loaderOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function Loader() {
  return (
    <div className="load-container">
      <Lottie options={loaderOptions} height={200} width={200} />
    </div>
  );
}

export default Loader;
