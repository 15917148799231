import { matchPath } from "react-router";

export const isMatchedPath = (
  locationPath: string,
  pathToCheck: string | string[],
  exact = true
) => {
  if (typeof pathToCheck === "string") {
    return !!matchPath(locationPath, {
      path: pathToCheck,
      exact,
    });
  }
  return pathToCheck.some(
    (path) =>
      !!matchPath(locationPath, {
        path: path,
        exact,
      })
  );
};
