import React, { ReactNode } from "react";
import { Formik, Form } from "formik";

import { IEmailFormShape } from "../../../interface";
import { emailValidationSchema } from "../../../constants";
import { StyledTextField } from "../../../../../shared/components/StyledTextField";
import { Button } from "../../../../../shared/components";
import { ButtonVariants } from "../../../../../shared/components/Button/Button";

import "./styles.scss";

export interface IEmailForm {
  title: ReactNode;
  submitButtonText: string;
  submitButtonVariant?: ButtonVariants;
  handleSubmit: (values: IEmailFormShape) => void;
  formData: IEmailFormShape;
  className?: string;
}

const EmailForm: React.FC<IEmailForm> = ({
  formData,
  handleSubmit,
  title,
  submitButtonText,
  submitButtonVariant,
}) => {
  return (
    <Formik
      validationSchema={emailValidationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      initialValues={formData}
      enableReinitialize
    >
      {({ values, errors, setFieldValue }) => {
        return (
          <div className="emailForm">
            <div className="emailForm-container">
              <h1 className="emailForm-header">{title}</h1>
              <Form autoComplete="off" className="emailForm-form">
                <div className="emailField">
                  <StyledTextField
                    autoComplete="off"
                    fullWidth
                    placeholder="Type your email here"
                    name="email"
                    errors={errors}
                    value={values.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("email", e.target.value.trim(), false)
                    }
                  />
                </div>
                <Button
                  type="submit"
                  radius={9}
                  variant={submitButtonVariant || "blue"}
                  textTransform="none"
                >
                  {submitButtonText}
                </Button>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default EmailForm;
