export const JOIN = "join";
export const CALL = "call";
export const EXIT = "exit";
export const OFFER = "offer";
export const ANSWER = "answer";
export const CANDIDATE = "candidate";
export const ROOM_NOT_FOUND = "room_not_found";
export const LEAVE = "leave";
export const CREATE = "create";
export const ROOMS = "rooms";
export const JOIN_MEETING = "join_meeting";
export const ROOM_HEARTBEAT = "room_heartbeat";
export const CONNECT = "connect";
export const DISCONNECT = "disconnect";
