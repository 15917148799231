export const convertIframeProp = (key: string): string => {
  const map: Record<string, string> = {
    allowfullscreen: "allowFullScreen",
    allowtransparency: "allowTransparency",
    frameborder: "frameBorder",
    marginheight: "marginHeight",
    marginwidth: "marginWidth",
    referrerpolicy: "referrerPolicy",
    srcdoc: "srcDoc",
  };

  return map[key] || key;
};
