import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { getRooms } from "../../../store/selectors";
import { Bench } from "../Bench";
import { rooms } from "../../../../App/constants";
import { chunkArray } from "../../../../../shared/utils/array";

import "./styles.scss";

const BenchesList: React.FC = () => {
  const realRooms = useSelector(getRooms());

  const ref = React.createRef<HTMLDivElement>();

  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [ref]);

  const benchRows = useMemo(() => {
    const benches = [];
    if (realRooms.length > 0) {
      const realRoom = { ...realRooms[0] };
      benches.push(...rooms);
      const { members: fakeMembers } = benches[5];
      fakeMembers.forEach((fakeMember) => {
        if (realRoom.members.length < 4) {
          realRoom.members.push(fakeMember);
        }
      });
      benches.splice(5, 1, realRoom);
    }

    return chunkArray(benches, 2);
  }, [realRooms]);

  return (
    <div className="benches-list">
      {benchRows.map((row, i) => (
        <div className="bench-row" key={i} ref={i === 2 ? ref : undefined}>
          {row.map((bench) => (
            <Bench bench={bench} key={bench.id} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default BenchesList;
