import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLocation } from "react-router";

import { authentificated } from "../../../Auth/store/selectors";
import { getConnections, getEvent } from "../../store/selectors";
import { peerConnection } from "../../utils";
import { connectSocket, getUserMedia, setRoomCode } from "../../store/actions";
import { Stream, VideoList } from "../../components/StreamContainer/";
import { useElementSize } from "../../../../shared/hooks/ElementSizeHook";
import { AlertDialog } from "../../../../shared/components/AlertDialog";
import copyTextHandler from "../../../../shared/utils/copyTextHandler";
import config from "../../../../config";
import { notificationActions } from "../../../Notifications/store/actions";
import { getBrowserInfo } from "../../../../shared/stores/browser/selectors";

import "./styles.scss";

const StreamContainer: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { roomCode } = useParams<{ roomCode: string; eventCode: string }>();
  const { isEnded, stream, rooms } = useSelector(getConnections());
  const isAuthenticated = useSelector(authentificated());
  const event = useSelector(getEvent());
  const browserInfo = useSelector(getBrowserInfo());

  const [permissionsAlertOpened, setPermissionsAlertOpened] = useState(false);
  const [canPlay, setCanPlay] = useState(false);

  const copyLinkHandler = useCallback(() => {
    if (stream?.active) {
      copyTextHandler(`${config.applicationUrl}${location.pathname}?initial=1`);
      dispatch(notificationActions.success("Copied to the clipboard"));
    } else {
      setPermissionsAlertOpened(true);
    }
  }, [dispatch, location.pathname, stream]);

  const watchPartyWrapperRef = useRef<HTMLDivElement>(null);
  const watchPartyContainerRef = useRef<HTMLDivElement>(null);

  useElementSize(watchPartyWrapperRef.current);

  const wrapperWidth = watchPartyWrapperRef.current?.offsetWidth ?? 0;
  const wrapperHeight = watchPartyWrapperRef.current?.offsetHeight ?? 0;
  const containerWidth = watchPartyContainerRef.current?.offsetWidth ?? 0;
  const containerHeight = watchPartyContainerRef.current?.offsetHeight ?? 0;

  const scale =
    Math.min(wrapperWidth / containerWidth, wrapperHeight / containerHeight) -
    0.05;

  useEffect(() => {
    if (isAuthenticated) {
      peerConnection.updateSocketToken();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (roomCode && stream === null && !isEnded && rooms.length > 0) {
      dispatch(setRoomCode(roomCode));
      dispatch(
        getUserMedia.request({
          video: true,
          audio: {
            echoCancellation: true,
          },
        })
      );
    }
  }, [dispatch, roomCode, isEnded, stream, rooms]);

  useEffect(() => {
    if (stream && roomCode && event && isAuthenticated) {
      dispatch(
        connectSocket.request({
          room_code: roomCode,
          meeting_id: event.id,
        })
      );
    }
  }, [stream, roomCode, dispatch, event, isAuthenticated]);

  useEffect(() => {
    browserInfo && browserInfo.name !== "safari" && setCanPlay(true);
  }, [browserInfo]);

  const fakeStreamVideos = [
    {
      src:
        "https://church-application.s3.amazonaws.com/demo-files/watch-party-demo-andy.mp4",
      userName: "Andy Mahon",
    },
    {
      src:
        "https://church-application.s3.amazonaws.com/demo-files/watch-party-demo-stephanie.mp4",
      userName: "Stephanie Leathe",
    },
  ];

  return (
    <>
      <AlertDialog
        open={permissionsAlertOpened}
        onConfirm={() => setPermissionsAlertOpened(false)}
        title="Oops"
        message="To invite a friend, you first need to enable audio and video permissions"
        mode="info"
        confirmText="Ok, got it"
        variant="brown"
      />
      <AlertDialog
        open={!canPlay}
        onConfirm={() => setCanPlay(true)}
        title="Play video"
        message="To start video, please click Play"
        mode="info"
        confirmText="Play"
        variant="brown"
      />
      <div className="stream-container" ref={watchPartyWrapperRef}>
        <div
          className="watch-party-container"
          style={{ transform: `translate(-50%, -50%) scale(${scale})` }}
          ref={watchPartyContainerRef}
        >
          <div className="stream-container-participants">
            {
              <VideoList
                copyLinkHandler={copyLinkHandler}
                fakeStreamVideos={fakeStreamVideos}
                canPlay={canPlay}
              />
            }
          </div>
          <div className="stream-container-stream">
            {event && <Stream event={event} canPlay={canPlay} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default StreamContainer;
