import React, { useState } from "react";
import "./styles.scss";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { FieldAttributes } from "formik";

const compileTextProps = () => ({
  classes: {
    root: "styledTextArea",
  },
  FormHelperTextProps: {
    classes: {
      root: "helper-text",
    },
  },
  InputLabelProps: {
    classes: {
      root: "text-label",
      error: "label-error",
      required: "text-label-required",
    },
    disableAnimation: true,
    shrink: true,
  },
});

const StyledTextField: React.FC<FieldAttributes<any>> = (props) => {
  const { name, errors, type } = props;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <TextField
      helperText={!!errors ? errors[name] : null}
      {...props}
      {...compileTextProps()}
      type={showPassword ? "text" : type}
      InputProps={{
        classes: {
          root: `text-field`,
          multiline: "text-field text-area",
          input: "textarea",
          disabled: "disabled-text",
        },
        disableUnderline: true,
        endAdornment: type === "password" && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? (
                <img
                  className="password-icon"
                  src="/icons/eye_icon.svg"
                  alt="eye icon"
                />
              ) : (
                <img
                  className="password-icon"
                  src="/icons/eye_off_icon.svg"
                  alt="eye icon"
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default StyledTextField;
