import { request } from "../../../shared/utils";
import { API, METHODS } from "../../../shared/constants";
import { ICreateEvent, IEmailFormShape, IEvent } from "../interface";

export default {
  get: (eventCode: string) =>
    request(METHODS.GET, API.EVENT.GET_BY_CODE(eventCode))(),
  createSermon: (body: ICreateEvent) =>
    request(METHODS.POST, API.EVENT.CREATE)(body),
  update: (id: number, body: Omit<Partial<IEvent>, "id">) =>
    request(METHODS.PUT, API.EVENT.UPDATE(id))(body),
  sendDecisionMaker: (body: IEmailFormShape) =>
    request(METHODS.POST, API.COMMUNITY.DECISION_EMAIL)(body),
};
