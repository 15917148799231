import React from "react";
import { INotification } from "../../interfaces";
import "./notistackContainer.scss";

const NotistackContent: React.FunctionComponent<INotification> = ({
  title,
  message,
  variant,
}) => {
  return (
    <div className="notificationContent">
      <p className="title">
        {title ? title : variant === "error" ? "Error occurred" : "Success"}
      </p>
      <p className="message">{message || ""}</p>
    </div>
  );
};

export default NotistackContent;
