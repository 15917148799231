import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { EventSideDialog } from "../EventSideDialog";
import { Messages } from "../../../mock";
import { DiscussionMessageItem } from "../DiscussionMessageItem";

import "./styles.scss";

export interface EventDiscussionsProps {
  open: boolean;
  onClose: () => void;
}

const EventDiscussions: React.FC<EventDiscussionsProps> = (props) => {
  const messageList = Messages.map((message) => (
    <DiscussionMessageItem key={message.id} message={message} />
  ));
  return (
    <EventSideDialog
      {...props}
      title="Men’s Bible Study Group"
      className="eventDiscussions-dialog"
    >
      <div className="eventDiscussions-message-list">{messageList}</div>
      <Tooltip title="This is not available during the demo" placement="top">
        <div className="eventDiscussions-input-wrapper">
          <div className="eventDiscussions-input">
            <div className="eventDiscussions-input-actions">
              <img src="/icons/add_icon.svg" alt="plus" />
            </div>
            <div className="eventDiscussions-input-field">
              Type something here
            </div>
            <div className="eventDiscussions-input-actions">
              <img src="/icons/send_message_light.svg" alt="send_message" />
            </div>
          </div>
        </div>
      </Tooltip>
    </EventSideDialog>
  );
};

export default EventDiscussions;
