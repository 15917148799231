import { createSelector } from "reselect";

import { AppState } from "../../../shared/interfaces/State";

const selectEvent = (state: AppState) => state.event;

export const getEvent = () =>
  createSelector(selectEvent, (state) => state.event);
export const getRooms = () =>
  createSelector(selectEvent, (state) => state.connections.rooms);
export const getConnections = () =>
  createSelector(selectEvent, (state) => state.connections);
export const getEventSettings = () =>
  createSelector(selectEvent, (state) => state.eventSettings);
