export enum AuthActionTypes {
  CHECK_USER = "@@AUTH/CHECK_USER",
  CHECK_USER_SUCCESS = "@@AUTH/CHECK_USER_SUCCESS",
  CHECK_USER_FAILURE = "@@AUTH/CHECK_USER_FAILURE",

  LOGIN = "@@AUTH/LOGIN",
  LOGIN_SUCCESS = "@@AUTH/LOGIN_SUCCESS",
  LOGIN_FAILURE = "@@AUTH/LOGIN_FAILURE",

  LOGOUT = "@@AUTH/LOGOUT",
  LOGOUT_SUCCESS = "@@AUTH/LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "@@AUTH/LOGOUT_FAILURE",

  REGISTRATION = "@@AUTH/REGISTRATION",
  REGISTRATION_SUCCESS = "@@AUTH/REGISTRATION_SUCCESS",
  REGISTRATION_FAILURE = "@@AUTH/REGISTRATION_FAILURE",
}
