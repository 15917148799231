import { useState, useEffect } from "react";

interface WindowHookState {
  width?: number;
  height?: number;
}

const useElementSize = (element: HTMLDivElement | null) => {
  const [elementSize, setElementSize] = useState<WindowHookState>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    let observer: any;
    if (element) {
      // @ts-ignore
      const updateSize = (entries?: ResizeObserverEntry) => {
        const container = entries ? entries[0]?.target : element;
        if (!!container) {
          setElementSize({
            width: container.offsetWidth,
            height: container.offsetHeight,
          });
        }
      };
      // typescript doesn't support ResizeObserver
      // @ts-ignore
      observer = new ResizeObserver(updateSize);
      observer.observe(element);
      updateSize();
    }
    return () => observer?.unobserve(element);
  }, [element]);
  return elementSize;
};

export default useElementSize;
