import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { Video } from "..";
import { getConnections, getEvent } from "../../../store/selectors";
import { getUser } from "../../../../Auth/store/selectors";
import { isUserHost } from "../../../utils/event";

import "./index.scss";

type Props = {
  fakeStreamVideos: { src: string; userName: string }[];
  copyLinkHandler: () => void;
  canPlay?: boolean;
};

export const VideoList: React.FC<Props> = ({
  fakeStreamVideos,
  copyLinkHandler,
  canPlay,
}) => {
  const { streams, stream } = useSelector(getConnections());
  const user = useSelector(getUser());
  const event = useSelector(getEvent());

  const isHost = useMemo(() => isUserHost(user, event), [event, user]);

  const friendStream = streams[streams.length - 1];

  return (
    <div className="video-list-wrapper">
      {fakeStreamVideos.map(({ src, userName }, i) => (
        <div className="video-wrapper" key={i}>
          <div className="video-container">
            <div className="videobox">
              {canPlay && (
                <video autoPlay controls={false} playsInline>
                  <source src={src} />
                </video>
              )}
            </div>
            <div className="member-name">{userName}</div>
          </div>
        </div>
      ))}
      {friendStream ? (
        <div className="video-wrapper">
          <div className="video-container">
            <div className="videobox">
              <Video srcObject={friendStream.stream} />
            </div>
            <div className="member-name">Friend</div>
          </div>
        </div>
      ) : (
        <div className="video-wrapper">
          <div className="video-container">
            <div
              className="videobox no-video clickable"
              onClick={isHost ? copyLinkHandler : undefined}
            >
              {isHost && (
                <>
                  <div className="icon invite" />
                  <span>Click here</span>
                  <span>to invite a friend</span>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="video-wrapper">
        {stream?.active ? (
          <div className="video-container">
            <div className="videobox">
              <Video srcObject={stream} muted />
            </div>
            <div className="member-name">You</div>
          </div>
        ) : (
          <div className="video-container">
            <div className="videobox no-video">
              <div className="icon permissions">You</div>
              <span>Enable permissions</span>
              <span>to see your video</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
