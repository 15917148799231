import { IMember } from "../../Member/interface/Member";

export enum EEventRestriction {
  all = "all",
  members = "members",
  invited = "invited",
}

export enum EEventType {
  meeting = "meeting",
  liveService = "live_service",
  stream = "stream",
}

export enum EEventStatus {
  draft = "draft",
  scheduled = "scheduled",
  started = "started",
  live = "live",
  lobby = "lobby",
  ended = "ended",
}

export enum EEventStreamType {
  FB = "facebook",
  YOUTUBE = "youtube",
  VIMEO = "vimeo",
  CUSTOM = "custom",
}

export enum EventDashboardTabs {
  chat = "chat",
  members = "members",
}

export interface IGreeter extends IMember {
  is_admin: boolean;
}

export interface IStreamEventMember {
  id: number;
  first_name: string;
  last_name: string;
  image_url?: string | null;
  atTable?: boolean;
}

export interface IEvent {
  id: number | null;
  code: string | null;
  meeting_id: number;
  meeting_password: string | null;
  name: string;
  subject: string;
  starting_at: string;
  ending_at: string;
  setup_time: number;
  post_event_time: number;
  restriction: EEventRestriction;
  community_id: number;
  producer_id: number;
  attendees: IMember[];
  greeters: IGreeter[];
  type: EEventType;
  status: EEventStatus;
  joinLink?: string;
  speaker_name: string | null;
  stream_type: EEventStreamType | null;
  stream_url: string | null;
  image_url: string;
  pre_images: string[];
  pre_images_interval: number | null;
  seats_per_table: number | null;
  host_id: number | null;
}

export interface ICreateEvent {
  name: string;
  subject: string;
  startTime: string;
  endTime: string;
  setup_time: number;
  post_event_time: number;
  restriction: EEventRestriction;
  presenters: number[];
  attendees: number[];
  greeters: IGreeter[];
  communityId: number;
  host?: number;
  presentersCodes: string[];
  type: EEventType;
  status: EEventStatus;
  stream_type: EEventStreamType | null;
  stream_url: string | null;
}

export interface IUpdateEvent {
  id: number;
  status: EEventStatus;
}

export interface IMessage {
  id: number;
  thread: number[][];
  user: IStreamEventMember;
  created: string;
}

export interface IEmailFormShape {
  email: string;
}
