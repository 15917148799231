import * as Yup from "yup";

import { IEmailFormShape } from "../interface";

export const emailValidationSchema = Yup.object<IEmailFormShape>().shape({
  email: Yup.string()
    .email("Incorrect Email format")
    .max(150, "Must be 150 characters or less")
    .required("Please enter your email"),
});
