import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { getConnections, getEventSettings } from "../../store/selectors";
import { TableItem } from "../../components/LobbyContainer/TableItem";
import { rooms } from "../../../App/constants";

import "./styles.scss";

const getItemClass = (idx: number) => {
  let classes: string = "";
  if (idx === 0) {
    return "three-column-edge two-column-edge";
  }

  // for tables grid with three columns
  if (idx % 3 === 0 && idx % 2 === 0) {
    classes += " three-column-edge";
  }

  // for tables grid with two columns
  if (idx % 2 === 0 && idx % 4 === 0) {
    classes += " two-column-edge";
  }
  return classes;
};

const LobbyContainer: React.FC = () => {
  const { rooms: realRooms } = useSelector(getConnections());
  const { activeDashboardTab } = useSelector(getEventSettings());

  const ref = React.createRef<HTMLDivElement>();

  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [ref]);

  const tables = useMemo(() => {
    const tables = [];
    if (realRooms.length > 0) {
      const realRoom = { ...realRooms[0] };
      realRoom.members = [];
      tables.push(...rooms);
      const { members: fakeMembers } = tables[4];
      fakeMembers.forEach((fakeMember) => {
        if (realRoom.members.length < 3) {
          realRoom.members.push(fakeMember);
        }
      });
      tables.splice(4, 1, realRoom);
    }

    return tables;
  }, [realRooms]);

  return (
    <div className="lobby-container">
      <div className="lobby-container-content">
        <div
          className={classnames("tables", {
            "tables-sideBar": !!activeDashboardTab,
          })}
        >
          {tables.map((table, idx) => (
            <TableItem
              reference={table.code ? ref : undefined}
              table_id={table.code}
              key={table.id}
              attendees={table.members}
              className={getItemClass(idx)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LobbyContainer;
