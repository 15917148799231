import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

import history from "../../../shared/history";
import { tokenHandler } from "../../../shared/utils";
import { getUserData, logout, login, registration } from "./actions";
import api from "../api";
import { AuthActionTypes } from "./constants";
import { startLoading, stopLoading } from "../../../shared/store/actions";
import { IUser } from "../interface/User";

function* getUserDataSaga() {
  try {
    yield put(startLoading());

    const user: IUser = yield call(api.getUserInfo);
    yield put(getUserData.success(user));
  } catch (error) {
    yield put(getUserData.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* logoutSaga() {
  yield localStorage.clear();
  yield put(logout.success());
}

function* registrationSaga({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const { token } = yield call(api.registration, payload);
    yield tokenHandler.set(token);
    yield put(getUserData.request());
    yield put(registration.success());
    history.push("/");
  } catch (error) {
    yield put(registration.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* loginSaga({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const { token } = yield call(api.login, payload);
    yield tokenHandler.set(token);
    yield put(getUserData.request());
    yield put(login.success());
  } catch (error) {
    yield put(registration.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* authSagas() {
  yield takeLatest(AuthActionTypes.LOGOUT, logoutSaga);
  yield takeLatest(AuthActionTypes.LOGIN, loginSaga);
  yield takeLatest(AuthActionTypes.CHECK_USER, getUserDataSaga);
  yield takeLatest(AuthActionTypes.REGISTRATION, registrationSaga);
}

export default authSagas;
