import React from "react";

import { Header } from "../../components/EventContainer";
import { Blocker } from "../../components/FullRoomContainer";

import "./styles.scss";

const FullRoomContainer: React.FC = () => (
  <div className="fullRoom">
    <Header />
    <Blocker
      title="Sorry, this demo is full"
      description={
        <>
          You were invited into Altar Live demo, but unfortunately there is no
          more room
          <br />
          in the demo for you to join. Click below to start your own demo
        </>
      }
    />
  </div>
);

export default FullRoomContainer;
