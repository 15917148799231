import React from "react";
import classnames from "classnames";

import { Button } from "../../../../../shared/components";

import "./styles.scss";

interface IEventSideDialog {
  open: boolean;
  onClose?: () => void;
  className?: string;
  title: string;
}

const EventSideDialog: React.FC<IEventSideDialog> = (props) => {
  const { onClose, title, children, open, className } = props;

  return open ? (
    <div className={classnames("eventSideDialog", className)}>
      <div className="eventSideDialog-container">
        <div className="eventSideDialog-header">
          <h2 className="eventSideDialog-title">{title}</h2>
          <Button
            width={24}
            variant="black-text"
            className="eventSideDialog-close-btn"
            onClick={onClose}
          >
            <img src="/icons/cross_icon_grey.svg" alt="cross icon" />
          </Button>
        </div>
        <div className="eventSideDialog-body">{children}</div>
      </div>
    </div>
  ) : null;
};

export default EventSideDialog;
