import io from "socket.io-client";
import { default as store } from "../../../index";
import { actions } from "../store";
import config from "../../../config";
import { IRoom } from "../interface";
import * as connectionTypes from "../constants/connectionTypes";
import { IUser } from "../../Auth/interface/User";
import { tokenHandler } from "../../../shared/utils";
import history from "../../../shared/history";

type ClientId = string;

export interface Data {
  room_code: string;
  from_id: ClientId;
  sdp: RTCSessionDescription;
  user: IUser;
}

class PeerConnection {
  private peerConnections: Array<{
    id: ClientId;
    pc: RTCPeerConnection;
  }>;
  private socket!: SocketIOClient.Socket & {
    query?: { authorization: string };
  };
  private stream: null | MediaStream;
  public room_code: null | string;
  private iceConnectionStatusMap: {
    [key: string]: { status: RTCIceConnectionState; retries: number };
  } = {};
  private heartBeatTimer: number | null;
  private cleanUpTimer: number | null;
  private connectionStateStatusMap: {
    [key: string]: RTCPeerConnectionState;
  } = {};

  constructor() {
    this.peerConnections = [];
    this.room_code = null;
    this.stream = null;
    this.heartBeatTimer = null;
    this.cleanUpTimer = null;

    this.socketInitialization();
  }

  private socketInitialization() {
    const token = tokenHandler.get();
    this.socket = io(`${config.wsApiUrl}`, {
      path: config.wsPath,
      transports: ["websocket", "polling"],
      query: {
        authorization: token ? `${token}` : "",
      },
    });

    this.socket.on(connectionTypes.JOIN, this.createOffer);
    this.socket.on(connectionTypes.OFFER, this.createAnswer);
    this.socket.on(connectionTypes.ANSWER, this.receivedAnswer);
    this.socket.on(connectionTypes.CANDIDATE, this.receivedCandidate);
    this.socket.on(connectionTypes.ROOMS, this.updateRooms);
    this.socket.on(connectionTypes.EXIT, ({ from_id }: Data) =>
      this.disconnect(from_id)
    );
    this.socket.on(connectionTypes.ROOM_HEARTBEAT, this.roomHeartBeatHandler);
    this.socket.on(connectionTypes.CONNECT, this.socketReConnectHandler);
    this.socket.on(
      connectionTypes.DISCONNECT,
      this.socketConnectionLostHandler
    );
  }

  public updateSocketToken() {
    this.socketInitialization();
  }

  public connectSocket = (
    stream: MediaStream,
    room_code: string | null,
    meeting_id: number | null
  ): Promise<string> => {
    return new Promise((resolve) => {
      this.stream = stream;

      const type =
        room_code === null ? connectionTypes.CREATE : connectionTypes.JOIN;

      if (room_code && type === connectionTypes.JOIN) {
        this.room_code = room_code;
        resolve(room_code);
      }

      if (meeting_id) {
        this.joinMeeting(meeting_id);
      }

      setTimeout(() => {
        this.heartBeatTimer = setInterval(this.heartBeat, 3000);
      }, 10000);
      this.cleanUpTimer = setInterval(this.cleanupConnections, 10000);

      this.socket?.emit(type, { meeting_id: meeting_id, room_code });
      this.socket.on(connectionTypes.CREATE, (data: any) => {
        this.socket.emit(connectionTypes.JOIN, { room_code: data.code });
        this.room_code = data.code;
        resolve(data.code);
      });
    });
  };

  public getRooms(meeting_id: number) {
    this.socket?.emit(connectionTypes.ROOMS, { meeting_id });
  }

  public joinMeeting(meeting_id: number) {
    this.socket?.emit(connectionTypes.JOIN_MEETING, { meeting_id });
  }

  private heartBeat = () => {
    if (window.navigator.onLine && this.socket.disconnected) {
      this.socket.connect();
    }
    if (!window.navigator.onLine) {
      this.socket.disconnect();
    }
    this.socket?.emit(connectionTypes.ROOM_HEARTBEAT, {
      code: this.room_code,
    });
  };

  private roomHeartBeatHandler = (data: Data) => {
    if (data.from_id !== this.socket.id) {
      const { from_id, user } = data;
      const pc_ids = this.peerConnections.map((p) => p.id);

      if (!pc_ids.includes(data.from_id)) {
        if (store) {
          const {
            event: {
              connections: { isConnecting },
            },
          } = store.getState();

          // if (store) {
          //   store.dispatch(notificationActions.info("Wooops", "Connection lost. Reconnecting."));
          // }

          if (this.room_code && isConnecting) {
            // tslint:disable-next-line: no-console
            console.log(new Date(), "reconnect-peer-connection");
            this.createOffer({ from_id, user });
          }
        }
      }
    }
  };

  private socketReConnectHandler = () => {
    if (store) {
      const {
        event: {
          connections: { isConnecting },
          event,
        },
      } = store.getState();

      if (isConnecting) {
        if (event?.id) {
          this.getRooms(event.id);
          this.joinMeeting(event.id);
        }
        if (this.room_code) {
          this.socket.emit(connectionTypes.JOIN, { room_code: this.room_code });
        }
      }
    }
  };

  private socketConnectionLostHandler = () => {
    this.socket.disconnect();
    // tslint:disable-next-line: no-console
    console.log(new Date(), "socket-connection-lost");
    this.peerConnections.forEach((p) => {
      this.disconnect(p.id);
    });

    this.socket.connect();
  };

  private prepareConnection = (
    clientId: ClientId,
    user: IUser
  ): RTCPeerConnection => {
    const configuration = {
      iceServers: [
        { urls: ["stun:us-turn6.xirsys.com"] },
        {
          username:
            "yU6dfv4vCtPJuhc9MZB9CCWzf1dihp7trQihy0BieGcJ5LiAHXOTnhMjhiFfuaT6AAAAAGA2NiBhbHRhcmxpdmU=",
          credential: "159ba37e-7692-11eb-9a70-0242ac140004",
          urls: [
            "turn:us-turn6.xirsys.com:80?transport=udp",
            "turn:us-turn6.xirsys.com:3478?transport=udp",
            "turn:us-turn6.xirsys.com:80?transport=tcp",
            "turn:us-turn6.xirsys.com:3478?transport=tcp",
            "turns:us-turn6.xirsys.com:443?transport=tcp",
            "turns:us-turn6.xirsys.com:5349?transport=tcp",
          ],
        },
      ],
    };
    const pc = new RTCPeerConnection(configuration);

    pc.onicecandidate = (e: RTCPeerConnectionIceEvent) =>
      this.handleIceCandidate(e, clientId);
    pc.ontrack = (e: RTCTrackEvent) => this.handleTrack(e, clientId, user);
    pc.oniceconnectionstatechange = () =>
      this.handleIceConnectionStateChange(clientId);
    // tslint:disable-next-line: no-console
    //   pc.onconnectionstatechange = (ev: any) => console.log(ev);
    // tslint:disable-next-line: no-console
    pc.onicecandidateerror = (error: any) => console.error(error);

    this.stream?.getTracks().forEach((track) => {
      if (this.stream !== null) {
        pc.addTrack(track, this.stream);
      }
    });
    this.setPeerConnection(clientId, pc);

    return pc;
  };

  private disconnect = async (clientId: ClientId): Promise<void> => {
    const pc = this.getPeerConnection(clientId);

    if (pc && store) {
      pc.close();
      pc.oniceconnectionstatechange = null;
      pc.ontrack = null;
      pc.oniceconnectionstatechange = null;
      this.peerConnections = this.peerConnections.filter(
        ({ id }) => id !== clientId
      );
      // tslint:disable-next-line: no-console
      console.log("disconnect - current peer connection", pc);
      // tslint:disable-next-line: no-console
      console.log("disconnect", this.peerConnections);

      store.dispatch(actions.removeStream(clientId));
    }
  };

  private getPeerConnection = (clientId: ClientId) => {
    const peerConnection = this.peerConnections.find(
      ({ id }) => id === clientId
    );

    return peerConnection?.pc;
  };

  private updateRooms = (data: IRoom[]) => {
    if (store) {
      const {
        event: {
          connections: { rooms },
        },
      } = store.getState();
      const { members } = data[0];

      if (rooms.length === 0 && members.length > 1) {
        history.push("/full");
        store.dispatch(actions.clearCurrentEvent());
        store.dispatch(actions.exitRoom.request());
      } else {
        store.dispatch(actions.updateRooms.success(data));
      }
    }
  };

  private setPeerConnection = (clientId: ClientId, pc: RTCPeerConnection) => {
    const index = this.peerConnections.findIndex(({ id }) => id === clientId);
    const peerConnection = {
      id: clientId,
      pc,
    };

    if (index < 0) {
      this.peerConnections = [...this.peerConnections, peerConnection];
    } else {
      this.peerConnections = [
        ...this.peerConnections.slice(0, index),
        peerConnection,
        ...this.peerConnections.slice(index + 1),
      ];
    }

    // tslint:disable-next-line: no-console
    console.log("setPeerConnection", this.peerConnections);
  };

  private handleTrack = async (
    e: RTCTrackEvent,
    clientId: ClientId,
    user: IUser
  ): Promise<void> => {
    const [stream] = e.streams;

    if (store) {
      const {
        event: {
          connections: { streams },
        },
      } = store.getState();

      if (!streams.find((item: any) => item.stream.id === stream.id)) {
        store.dispatch(actions.addStream({ clientId, stream, user }));
      }
    }
  };

  private handleIceCandidate = (
    e: RTCPeerConnectionIceEvent,
    clientId: ClientId
  ): void => {
    // for Tricle ICE
    if (!!e.candidate) {
      const data = {
        to_id: clientId,
        room_code: this.room_code,
        sdp: {
          type: "candidate",
          ice: e.candidate,
        },
      };

      this.socket?.emit(connectionTypes.CANDIDATE, data);
    }

    // for Vanilla ICE
    // if (this.pc?.localDescription) {
    //   this.sendSDP(this.pc.localDescription)
    // }
  };

  private cleanupConnections = () => {
    this.peerConnections.forEach((p) => {
      if (
        this.connectionStateStatusMap[p.id] === p.pc.connectionState &&
        p.pc.connectionState !== "connected" &&
        p.pc.connectionState !== "new"
      ) {
        // tslint:disable-next-line: no-console
        console.log(
          new Date(),
          "clenupConnections",
          "connectionState - disconnect"
        );
        this.disconnect(p.id);
      }
      if (
        p.pc.iceConnectionState === "failed" ||
        p.pc.iceConnectionState === "closed" ||
        p.pc.iceConnectionState === "disconnected"
      ) {
        // tslint:disable-next-line: no-console
        console.log(
          new Date(),
          "clenupConnections",
          "iceConnectionState - disconnect"
        );
        this.disconnect(p.id);
      }
      this.connectionStateStatusMap[p.id] = p.pc.connectionState;
    });
  };

  private handleIceConnectionStateChange = async (clientId: ClientId) => {
    const pc = this.getPeerConnection(clientId);

    if (pc) {
      if (!this.iceConnectionStatusMap[clientId]) {
        this.iceConnectionStatusMap[clientId] = {
          status: "new",
          retries: 0,
        };
      }
      switch (pc.iceConnectionState) {
        case "disconnected":
        case "failed":
          // tslint:disable-next-line: no-console
          console.log(
            new Date(),
            "handleIceConnectionStateChange",
            "disconnect-call"
          );
          this.disconnect(clientId);
          break;
        default:
          break;
      }
      this.iceConnectionStatusMap[clientId].status = pc.iceConnectionState;
    }
  };

  private createOffer = async ({ from_id, user }: any): Promise<void> => {
    if (this.getPeerConnection(from_id)) {
      return;
    }

    const pc = this.prepareConnection(from_id, user);

    const sessionDescription = await pc.createOffer();

    await pc.setLocalDescription(sessionDescription);

    this.sendSDP(from_id, sessionDescription);
  };

  public createAnswer = async ({ from_id, sdp, user }: Data): Promise<void> => {
    const receivedOffer = new RTCSessionDescription(sdp);
    const pc = this.prepareConnection(from_id, user);

    if (!pc) {
      return;
    }

    try {
      await pc.setRemoteDescription(receivedOffer);
      const sessionDescription = await pc.createAnswer();

      await pc.setLocalDescription(sessionDescription);

      this.sendSDP(from_id, sessionDescription);
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error("createAnswer", e);
    }
  };

  public receivedAnswer = async ({
    from_id,
    sdp,
    user,
  }: Data): Promise<void> => {
    const receivedAnswer = new RTCSessionDescription(sdp);
    const pc = this.getPeerConnection(from_id);

    if (!pc) {
      return;
    }

    pc.ontrack = (e: RTCTrackEvent) => this.handleTrack(e, from_id, user);

    try {
      await pc.setRemoteDescription(receivedAnswer);
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error("receivedAnswer", e);
    }
  };

  public receivedCandidate = ({ from_id, sdp }: Record<string, any>) => {
    const candidate = new RTCIceCandidate(sdp.ice);
    const pc = this.getPeerConnection(from_id);

    if (pc) {
      pc.addIceCandidate(candidate).catch(() => {
        pc.setRemoteDescription(sdp);
      });
    }
  };

  private sendSDP = (
    clientId: ClientId,
    sessionDescription: RTCSessionDescription | RTCSessionDescriptionInit
  ) => {
    const data = {
      to_id: clientId,
      room_code: this.room_code,
      sdp: sessionDescription,
    };

    if (sessionDescription.type) {
      this.socket?.emit(sessionDescription.type, data);
    }
  };

  public exit = () => {
    if (this.socket) {
      this.socket?.emit(connectionTypes.EXIT, { room_code: this.room_code });
    }
    this.peerConnections = [];

    if (this.heartBeatTimer) {
      clearInterval(this.heartBeatTimer);
    }
    if (this.cleanUpTimer) {
      clearInterval(this.cleanUpTimer);
    }
    this.heartBeatTimer = null;
    this.cleanUpTimer = null;
  };
}

export const peerConnection = new PeerConnection();
