import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { getConnections, getEvent } from "../../store/selectors";
import { authentificated } from "../../../Auth/store/selectors";
import { peerConnection } from "../../utils";
import { connectSocket, getUserMedia, setRoomCode } from "../../store/actions";
import { VideoList } from "../../components/StreamContainer/VideoList";
import config from "../../../../config";
import { useElementSize } from "../../../../shared/hooks/ElementSizeHook";
import copyTextHandler from "../../../../shared/utils/copyTextHandler";
import { notificationActions } from "../../../Notifications/store/actions";
import { AlertDialog } from "../../../../shared/components/AlertDialog";
import { useLocation } from "react-router-dom";
import { getBrowserInfo } from "../../../../shared/stores/browser/selectors";

import "./styles.scss";

const TableContainer: React.FC = () => {
  const { roomCode } = useParams<{ roomCode: string }>();
  const { isEnded, stream, isConnecting } = useSelector(getConnections());
  const isAuthenticated = useSelector(authentificated());
  const event = useSelector(getEvent());
  const dispatch = useDispatch();
  const location = useLocation();
  const browserInfo = useSelector(getBrowserInfo());

  const [permissionsAlertOpened, setPermissionsAlertOpened] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [canPlay, setCanPlay] = useState(false);

  const copyLinkHandler = useCallback(() => {
    if (stream?.active) {
      copyTextHandler(`${config.applicationUrl}${location.pathname}?initial=1`);
      dispatch(notificationActions.success("Copied to the clipboard"));
    } else {
      setPermissionsAlertOpened(true);
    }
  }, [dispatch, location.pathname, stream]);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, [mounted]);

  useEffect(() => {
    if (isAuthenticated) {
      peerConnection.updateSocketToken();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (roomCode && stream === null && !isEnded) {
      dispatch(setRoomCode(roomCode));
      dispatch(
        getUserMedia.request({
          video: true,
          audio: {
            echoCancellation: true,
          },
        })
      );
    }
  }, [dispatch, roomCode, isEnded, stream]);

  useEffect(() => {
    browserInfo && browserInfo.name !== "safari" && setCanPlay(true);
  }, [browserInfo]);

  React.useEffect(() => {
    if (!isConnecting && stream && roomCode && event && isAuthenticated) {
      dispatch(
        connectSocket.request({ room_code: roomCode, meeting_id: event.id })
      );
    }
  }, [isConnecting, stream, roomCode, dispatch, event, isAuthenticated]);

  const fakeStreamVideos = [
    {
      src:
        "https://church-application.s3.amazonaws.com/demo-files/table-demo-andy.mp4",
      userName: "Andy Mahon",
    },
    {
      src:
        "https://church-application.s3.amazonaws.com/demo-files/table-demo-stephanie.mp4",
      userName: "Stephanie Leathe",
    },
  ];

  const breakoutRoomWrapperRef = useRef<HTMLDivElement>(null);
  const videoListRef = React.useRef<HTMLDivElement>(null);

  useElementSize(breakoutRoomWrapperRef.current);

  const wrapperWidth = breakoutRoomWrapperRef.current?.offsetWidth ?? 0;
  const wrapperHeight = breakoutRoomWrapperRef.current?.offsetHeight ?? 0;
  const videoListWidth = videoListRef.current?.offsetWidth ?? 0;
  const videoListHeight = videoListRef.current?.offsetHeight ?? 0;

  const scale = Math.min(
    wrapperWidth / videoListWidth,
    wrapperHeight / videoListHeight
  );
  return (
    <>
      <AlertDialog
        open={permissionsAlertOpened}
        onConfirm={() => setPermissionsAlertOpened(false)}
        title="Oops"
        message="To invite a friend, you first need to enable audio and video permissions"
        mode="info"
        confirmText="Ok, got it"
        variant="brown"
      />
      <AlertDialog
        open={!canPlay}
        onConfirm={() => setCanPlay(true)}
        title="Play video"
        message="To start video, please click Play"
        mode="info"
        confirmText="Play"
        variant="brown"
      />
      <div className="breakout-room-wrapper" ref={breakoutRoomWrapperRef}>
        <div className="breakout-room-inner-wrapper">
          <div
            className="video-list-container"
            ref={videoListRef}
            style={{ transform: `translate(-50%, -50%) scale(${scale})` }}
          >
            <VideoList
              fakeStreamVideos={fakeStreamVideos}
              copyLinkHandler={copyLinkHandler}
              canPlay={canPlay}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TableContainer;
