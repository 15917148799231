import produce from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { EventStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const initialState: EventStateType = {
  event: null,
  eventError: null,
  connections: {
    isConnecting: false,
    isEnded: false,
    error: null,
    roomCode: null,
    stream: null,
    streams: [],
    rooms: [],
    isMatesMuted: false,
  },
  eventSettings: {
    activeDashboardTab: null,
    seatSelectorOpened: false,
  },
};

const reducer = createReducer<EventStateType, Action>(initialState)
  .handleAction(actions.getEventByCode.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.event = action.payload;
      nextState.eventError = null;
    })
  )
  .handleAction(actions.getEventByCode.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.event = null;
      nextState.eventError = action.payload;
    })
  )
  .handleAction(actions.updateEvent.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.event = action.payload;
      nextState.eventError = null;
    })
  )
  .handleAction(actions.updateEvent.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.eventError = action.payload;
    })
  )
  .handleAction(actions.createEvent.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.event = action.payload;
      nextState.eventError = null;
    })
  )
  .handleAction(actions.createEvent.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.eventError = action.payload;
    })
  )
  .handleAction(actions.clearCurrentEvent, (state) =>
    produce(state, (nextState) => {
      nextState.event = null;
      nextState.eventError = null;
    })
  )
  .handleAction(actions.updateRooms.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.connections.rooms = action.payload;
    })
  )
  .handleAction(actions.setRoomCode, (state, action) =>
    produce(state, (nextState) => {
      nextState.connections.roomCode = action.payload;
    })
  )
  .handleAction(actions.getUserMedia.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.connections.stream = action.payload;
    })
  )
  .handleAction(actions.getUserMedia.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.connections.error = action.payload;
    })
  )
  .handleAction(actions.connectSocket.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.connections.roomCode = action.payload;
      nextState.connections.isConnecting = true;
      nextState.connections.isEnded = false;
    })
  )
  .handleAction(actions.connectSocket.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.connections.error = action.payload;
    })
  )
  .handleAction(actions.addStream, (state, action) =>
    produce(state, (nextState) => {
      nextState.connections.streams = state.connections.streams.find(
        ({ stream }) => stream.id === action.payload.stream.id
      )
        ? state.connections.streams
        : [...state.connections.streams, action.payload];
    })
  )
  .handleAction(actions.removeStream, (state, action) =>
    produce(state, (nextState) => {
      nextState.connections.streams = state.connections.streams.filter(
        ({ clientId }) => clientId !== action.payload
      );
    })
  )
  .handleAction(actions.updateEventSettings, (state, action) =>
    produce(state, (nextState) => {
      nextState.eventSettings = { ...state.eventSettings, ...action.payload };
    })
  )
  .handleAction(actions.clearEventSettings, (state) =>
    produce(state, (nextState) => {
      nextState.eventSettings = initialState.eventSettings;
    })
  )
  .handleAction(actions.updateStateEvent, (state, action) =>
    produce(state, (nextState) => {
      nextState.event = state.event
        ? { ...state.event, ...action.payload }
        : null;
    })
  )
  .handleAction(actions.exitRoom.success, (state) =>
    produce(state, (nextState = initialState) => {
      nextState.connections.isConnecting = false;
      nextState.connections.isMatesMuted = false;
      nextState.connections.roomCode = null;
      nextState.connections.stream = null;
      nextState.connections.streams = [];
    })
  )
  .handleAction(actions.exitRoom.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.connections.error = action.payload;
    })
  );
export { reducer as EventReducer };
