import React from "react";

import "./styles.scss";

interface IDecisionCard {
  title?: string;
}

const DecisionCard: React.FC<IDecisionCard> = ({ title, children }) => {
  return (
    <div className="decision-card">
      <div className="decision-card-header">
        <h2 className="decision-card-title">{title}</h2>
      </div>
      <div className="decision-card-body">{children}</div>
    </div>
  );
};
export default DecisionCard;
