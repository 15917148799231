import { NotificationActionTypes } from "./constants";
import { action, Action } from "typesafe-actions";
import { NotificationVariants } from "./constants";

export type NotificationActions = {
  [key: string]: (title: string, message?: string) => Action<any>;
};
export const notificationActions: NotificationActions = {};

NotificationVariants.forEach((variant) => {
  notificationActions[variant] = (title: string, message?: string) => {
    const reassignedMessage = message ? message : title;
    const reassignedTitle = !message ? null : title;

    return action(NotificationActionTypes.ENQUEUE_SNACKBAR, {
      key: (new Date().getTime() + Math.random()).toString(),
      message: reassignedMessage,
      title: reassignedTitle,
      variant,
    });
  };
});

export const removeSnackbar = (key: string) =>
  action(NotificationActionTypes.REMOVE_SNACKBAR, key);
