import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarProvider } from "notistack";
import ReactGA from "react-ga";

import { Content, NotFound } from "../../shared/components";
import { EventContainer } from "../Event/containers/EventContainer";
import { DecisionContainer } from "../Event/containers/DecisionContainer";
import { FullRoomContainer } from "../Event/containers";
import { getLoading } from "../../shared/store/selectors";
import { NotistackActionButton } from "../Notifications/components/NotistackActionButton";
import { NotistackContainer } from "../Notifications/components/NotistackContainer";
import { Loader } from "../../shared/components/Loader";
import { setBrowserInfo } from "../../shared/stores/browser/actions";
import config from "../../config";
import { EndedContainer } from "../Event/containers/EndedContainer";

const App = () => {
  const loading = useSelector(getLoading());

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (config.googleAnalyticsId) {
      ReactGA.initialize(config.googleAnalyticsId);

      history.listen((location) => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
      });
    }
  }, [history]);

  useEffect(() => {
    dispatch(setBrowserInfo());
  }, [dispatch]);

  return (
    <div className="App">
      <SnackbarProvider
        classes={{
          variantSuccess: "defaultSnackStyle snackStyle-success",
          variantError: "defaultSnackStyle snackStyle-error",
          variantInfo: "defaultSnackStyle snackStyle-info",
        }}
        hideIconVariant
        maxSnack={8}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        preventDuplicate={true}
        autoHideDuration={3000}
        action={(key) => <NotistackActionButton snackKey={key} />}
      >
        <NotistackContainer />
        <Content>
          {loading && <Loader />}
          <Switch>
            <Route path="/full" component={FullRoomContainer} />
            <Route path="/ended" component={EndedContainer} />
            <Route path="/decision" component={DecisionContainer} />
            <Route path="/:eventCode" component={EventContainer} />
            <Route path="/" component={EventContainer} />
            <Route component={NotFound} />
          </Switch>
        </Content>
      </SnackbarProvider>
    </div>
  );
};

export default App;
