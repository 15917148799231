import styled, { css } from "styled-components";

export type ButtonVariants =
  | "orange"
  | "orange-text"
  | "red"
  | "red-text"
  | "red-outline"
  | "blue"
  | "blue-outline"
  | "blue-text"
  | "black-text"
  | "white"
  | "white-text"
  | "white-outline"
  | "disabled"
  | "gray-text"
  | "purple-text"
  | "purple"
  | "disabled-text"
  | "carrot";

export interface IStyledButton {
  width?: string | number;
  height?: string | number;
  radius?: string | number;
  variant?: ButtonVariants;
  shadow?: boolean;
  textTransform?: string;
}

export default styled.button`
  height: ${({ height }: IStyledButton) => (height ? `${height}px` : "40px")};
  border-radius: ${({ radius }: IStyledButton) =>
    radius ? `${radius}px` : "9px"};
  text-transform: ${({ textTransform = "capitalize" }: IStyledButton) =>
    textTransform};
  font-family: "Heebo", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15em;
  cursor: pointer;
  outline: none;
  width: ${({ width }: IStyledButton) => (width ? `${width}px` : "100%")};
  ${({ shadow }) =>
    shadow &&
    css`
      box-shadow: 0px 4px 9px rgba(48, 48, 48, 0.07);
    `}
  transition: 0.2s;
  ${({ variant }) => {
    switch (variant) {
      case "orange":
        return css`
          background: #f09761;
          color: #ffffff;
          border: none;
        `;
      case "orange-text":
        return css`
          background: transparent;
          color: #f09761;
          border: none;
        `;
      case "red":
        return css`
          background: #f6766f;
          color: #ffffff;
          border: none;
        `;
      case "red-text":
        return css`
          background: transparent;
          color: #f6766f;
          border: none;
        `;
      case "red-outline":
        return css`
          background: transparent;
          color: #f6766f;
          border: 1px solid #f6766f !important;
        `;
      case "blue":
        return css`
          background: #7b9eb9;
          color: #ffffff;
          border: none;
        `;
      case "blue-outline":
        return css`
          background: transparent;
          color: #7b9eb9;
          border: 1px solid #7b9eb9 !important;
        `;
      case "blue-text":
        return css`
          background: transparent;
          color: #7b9eb9;
          border: none;
        `;
      case "black-text":
        return css`
          background: transparent;
          color: #181711;
          border: none;
        `;
      case "white":
        return css`
          background: #ffffff;
          color: #7b9eb9;
          border: none;
        `;
      case "white-text":
        return css`
          background: transparent;
          color: #fff;
          border: none;
        `;
      case "white-outline":
        return css`
          background: transparent;
          color: #fff;
          border: 1px solid #fff !important;
        `;
      case "disabled":
        return css`
          background: #b8b8b8;
          color: #ffffff;
          border: none;
        `;
      case "disabled-text":
        return css`
          background: transparent;
          color: #b8b8b8;
          border: none;
        `;
      case "gray-text":
        return css`
          background: transparent;
          color: #b8b8b8;
          border: none;
        `;
      case "purple":
        return css`
          background: #93808d;
          color: #ffffff;
          border: none;
        `;
      case "carrot":
        return css`
          background: #ef7171;
          color: #ffffff;
          border: none;
        `;
      case "purple-text":
        return css`
          background: transparent;
          color: #93808d;
          border: none;
        `;
      default:
        return css`
          background: #ffffff;
          color: #6f92ff;
          border: none;
        `;
    }
  }}
`;
