import { AuthShape } from "../Auth/interface";
import { IRoom } from "../Event/interface";

export const LOGIN_REDIRECT_LINK = "/auth/";

export const communityId = 194;
export const users: AuthShape[] = [
  {
    email: "oleksandr.rypun+manager1@923digital.com",
    password: "Qwe12345",
  },
  {
    email: "oleksandr.rypun+manager2@923digital.com",
    password: "Qwe12345",
  },
  {
    email: "oleksandr.rypun+manager3@923digital.com",
    password: "Qwe12345",
  },
  {
    email: "oleksandr.rypun+manager4@923digital.com",
    password: "Qwe12345",
  },
];
export const streamUrl = "https://www.youtube.com/watch?v=123456";
export const rooms: IRoom[] = [
  {
    id: 1,
    code: "",
    meeting_id: 1,
    members: [
      {
        id: 31,
        first_name: "Maria",
        last_name: "Garcia",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 32,
        first_name: "Daniel",
        last_name: "Lopez",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 1,
        first_name: "Courtney",
        last_name: "Henry",
        image_url: "/images/members/member_1.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 2,
        first_name: "Esther",
        last_name: "Howard",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
    ],
  },
  {
    id: 2,
    code: "",
    meeting_id: 1,
    members: [
      {
        id: 3,
        first_name: "Cody",
        last_name: "Fisher",
        image_url: "/images/members/member_2.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
    ],
  },
  {
    id: 3,
    code: "",
    meeting_id: 1,
    members: [
      {
        id: 4,
        first_name: "Jerome",
        last_name: "Bell",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 5,
        first_name: "Wade",
        last_name: "Warren",
        image_url: "/images/members/member_3.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
    ],
  },
  {
    id: 4,
    code: "",
    meeting_id: 1,
    members: [
      {
        id: 33,
        first_name: "Karon",
        last_name: "Scuderi",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 6,
        first_name: "Eleanor",
        last_name: "Pena",
        image_url: "/images/members/member_4.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 7,
        first_name: "Darrell",
        last_name: "Steward",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 8,
        first_name: "Theresa",
        last_name: "Webb",
        image_url: "/images/members/member_5.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
    ],
  },
  {
    id: 5,
    code: "",
    meeting_id: 1,
    members: [
      null,
      {
        id: 9,
        first_name: "Floyd",
        last_name: "Miles",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 10,
        first_name: "Fernando",
        last_name: "Shires",
        image_url: "/images/members/member_6.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      null,
    ],
  },
  {
    id: 6,
    code: "",
    meeting_id: 1,
    members: [
      {
        id: 11,
        first_name: "Ivey",
        last_name: "Schwebt",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      null,
      {
        id: 12,
        first_name: "Joana",
        last_name: "Hanselman",
        image_url: "/images/members/member_7.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      null,
    ],
  },
  {
    id: 7,
    code: "",
    meeting_id: 1,
    members: [
      {
        id: 13,
        first_name: "Sal",
        last_name: "Depue",
        image_url: "/images/members/member_8.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      null,
      {
        id: 14,
        first_name: "John",
        last_name: "Smith",
        image_url: "/images/members/member_9.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
    ],
  },
  {
    id: 8,
    code: "",
    meeting_id: 1,
    members: [
      {
        id: 15,
        first_name: "Sadie",
        last_name: "Betrüger",
        image_url: "/images/members/member_10.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 16,
        first_name: "Michael",
        last_name: "Johnson",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
    ],
  },
  {
    id: 9,
    code: "",
    meeting_id: 1,
    members: [
      {
        id: 17,
        first_name: "Jane",
        last_name: "Rolfes",
        image_url: "/images/members/member_11.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 18,
        first_name: "Mark",
        last_name: "Williams",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 19,
        first_name: "Jeniffer",
        last_name: "Winkel",
        image_url: "/images/members/member_12.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
    ],
  },
  {
    id: 10,
    code: "",
    meeting_id: 1,
    members: [
      null,
      {
        id: 20,
        first_name: "Hugo",
        last_name: "Simoneau",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 21,
        first_name: "Nolan",
        last_name: "Mcevoy",
        image_url: "/images/members/member_13.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
    ],
  },
  {
    id: 11,
    code: "",
    meeting_id: 1,
    members: [
      null,
      {
        id: 22,
        first_name: "Idell",
        last_name: "Lafever",
        image_url: "/images/members/member_14.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
    ],
  },
  {
    id: 12,
    code: "",
    meeting_id: 1,
    members: [
      null,
      {
        id: 23,
        first_name: "Carolina",
        last_name: "Risiko",
        image_url: "/images/members/member_15.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 24,
        first_name: "Matilde",
        last_name: "Geschichte",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 25,
        first_name: "Chris",
        last_name: "Brown",
        image_url: "/images/members/member_16.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
    ],
  },
  {
    id: 13,
    code: "",
    meeting_id: 1,
    members: [
      {
        id: 26,
        first_name: "David",
        last_name: "Jones",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      null,
      {
        id: 27,
        first_name: "Paul",
        last_name: "Rodriguez",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
    ],
  },
  {
    id: 14,
    code: "",
    meeting_id: 1,
    members: [
      {
        id: 28,
        first_name: "Jean",
        last_name: "Clouse",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 29,
        first_name: "Winifred",
        last_name: "Trigg",
        image_url: "",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
      {
        id: 30,
        first_name: "Jame",
        last_name: "Walcott",
        image_url: "/images/members/member_17.png",
        phone_number: null,
        email: "test@test.test",
        note: null,
        role: null,
        community_id: communityId,
      },
    ],
  },
];
