import React, { useMemo } from "react";
import classnames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";

import { EventDashboardTabs } from "../../../interface";

import "./styles.scss";

interface IEventDashboard {
  activeTab: EventDashboardTabs | null;
  onChange: (tab: EventDashboardTabs | null) => void;
}

const tabIconsSrc = [
  {
    activeIcon: "discussion_icon_white.svg",
    defaultIcon: "discussion_icon.svg ",
    type: [EventDashboardTabs.chat],
  },
  {
    activeIcon: "member_tab_icon_white.svg",
    defaultIcon: "member_tab_icon.svg ",
    type: [EventDashboardTabs.members],
  },
  {
    defaultIcon: "bell.svg",
  },
  {
    defaultIcon: "calendar.svg",
  },
  {
    defaultIcon: "praying.svg",
  },
  {
    defaultIcon: "guide_icon.svg",
  },
];

const EventDashboard: React.FC<IEventDashboard> = ({ activeTab, onChange }) => {
  const tabs = useMemo(() => {
    return tabIconsSrc.map(({ activeIcon, type, defaultIcon }, idx) => (
      <div
        key={idx}
        className={classnames("eventDashboard-tabs-item", {
          active: activeTab && type?.includes(activeTab),
          clickable: !!type,
        })}
        onClick={() => type && onChange(type[0])}
      >
        <Tooltip
          title={!type ? "This is not available during the demo" : ""}
          placement="top"
        >
          <img
            src={`/icons/${
              activeTab && type?.includes(activeTab) ? activeIcon : defaultIcon
            }`}
            alt={`${activeTab || "tab"} icon`}
          />
        </Tooltip>
      </div>
    ));
  }, [activeTab, onChange]);
  return (
    <div className={classnames("eventDashboard")}>
      <div className="eventDashboard-tabs">{tabs}</div>
    </div>
  );
};
export default EventDashboard;
