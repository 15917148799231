import React, { useState } from "react";

import { StyledTextField } from "../../../../../shared/components/StyledTextField";
import { currentMember, EventMembers } from "../../../mock";
import { EventMemberListItem } from "../EventMemberListItem";
import { EventSideDialog } from "../EventSideDialog";

import "./styles.scss";

interface IEventMembersDialog {
  open: boolean;
  onClose?: () => void;
  className?: string;
}

const EventMembersDialog: React.FC<IEventMembersDialog> = (props) => {
  const { onClose, open } = props;

  const [searchValue, setSearchValue] = useState("");

  const membersATable = EventMembers.filter(
    (member) => member.atTable
  ).map((member) => <EventMemberListItem key={member.id} member={member} />);
  const openToJoin = EventMembers.filter(
    (member) => !member.atTable
  ).map((member) => <EventMemberListItem key={member.id} member={member} />);
  return (
    <EventSideDialog title="Members" open={open} onClose={onClose}>
      <div className="eventMembersDialog">
        <div className="eventMembersDialog-search">
          <StyledTextField
            fullWidth
            placeholder="Type something here"
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchValue(e.target.value)
            }
          />
          {searchValue && (
            <div
              className="clear editIcons editIcons-delete"
              onClick={() => setSearchValue("")}
            />
          )}
        </div>
        <div className="eventMembersDialog-list own-member">
          <EventMemberListItem member={currentMember} own />
        </div>
        <div className="eventMembersDialog-list">
          {!!openToJoin.length && (
            <div className="eventMembersDialog-list-notSeating">
              <div className="eventMembersDialog-list-title">
                Not Seated, Open To Join
              </div>
              <div className="eventMembersDialog-members-list">
                {openToJoin}
              </div>
            </div>
          )}
          {!!membersATable.length && (
            <div className="eventMembersDialog-list-seated">
              <div className="eventMembersDialog-list-title">
                Already seated
              </div>
              <div className="eventMembersDialog-list">{membersATable}</div>
            </div>
          )}
        </div>
      </div>
    </EventSideDialog>
  );
};

export default EventMembersDialog;
