import { IMessage, IStreamEventMember } from "../interface";

export const EventMembers: IStreamEventMember[] = [
  {
    id: 1,
    first_name: "Courtney",
    last_name: "Henry",
    image_url: "/images/members/Courtney.png",
    atTable: true,
  },
  {
    id: 2,
    first_name: "Esther",
    last_name: "Howard",
    image_url: "",
    atTable: true,
  },
  {
    id: 3,
    first_name: "Cody",
    last_name: "Fisher",
    image_url: "/images/members/Cody.png",
    atTable: true,
  },
  {
    id: 4,
    first_name: "Jerome",
    last_name: "Bell",
    image_url: "",
    atTable: true,
  },
  {
    id: 5,
    first_name: "Theresa",
    last_name: "Webb",
    image_url: "/images/members/Theresa.png",
    atTable: true,
  },
  {
    id: 6,
    first_name: "Eleanor",
    last_name: "Pena",
    image_url: "",
  },
  {
    id: 7,
    first_name: "Darrell",
    last_name: "Steward",
    image_url: "",
  },
  {
    id: 8,
    first_name: "Wade",
    last_name: "Warren",
    image_url: "/images/members/Wade.png",
  },
  {
    id: 9,
    first_name: "Floyd",
    last_name: "Miles",
    image_url: "/images/members/Floyd.png",
  },
];

export const currentMember: IStreamEventMember = {
  id: 0,
  first_name: "You",
  last_name: "",
  image_url: "/images/members/current_user.png",
};

export const ChatInterlocutor = {
  id: 10,
  first_name: "Ann",
  last_name: "Simmons",
  image_url: "/images/members/Simmons.png",
};

export const Messages: IMessage[] = [
  {
    id: 1,
    thread: [[243, 243, 134]],
    user: ChatInterlocutor,
    created: "8m",
  },
  {
    id: 2,
    thread: [[217], [94], [128]],
    user: ChatInterlocutor,
    created: "6m",
  },
  {
    id: 3,
    thread: [[243, 198]],
    user: currentMember,
    created: "5m",
  },
  {
    id: 4,
    thread: [[243, 141]],
    user: ChatInterlocutor,
    created: "3m",
  },
  {
    id: 5,
    thread: [[124]],
    user: currentMember,
    created: "3m",
  },
  {
    id: 6,
    thread: [[243, 141]],
    user: ChatInterlocutor,
    created: "2m",
  },
  {
    id: 7,
    thread: [[124]],
    user: currentMember,
    created: "1m",
  },
];
