import produce from "immer";

import { AuthStateType } from "../interface";
import { tokenHandler } from "../../../shared/utils";
import * as actions from "./actions";
import { ActionType, createReducer } from "typesafe-actions";

type Action = ActionType<typeof actions>;

const initialState: AuthStateType = {
  authentificated: !!tokenHandler.get(),
  user: null,
};

const reducer = createReducer<AuthStateType, Action>(initialState)
  .handleAction(actions.login.success, (state) =>
    produce(state, (nextState) => {
      nextState.authentificated = true;
    })
  )
  .handleAction(actions.getUserData.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.user = action.payload;
    })
  )
  .handleAction(actions.getUserData.failure, (state) =>
    produce(state, (nextState) => {
      nextState.user = null;
    })
  )
  .handleAction(actions.logout.success, (state) =>
    produce(state, (nexState) => {
      nexState.user = null;
      nexState.authentificated = false;
    })
  );
export { reducer as AuthReducer };
