export enum EventActionTypes {
  GET_BY_CODE = "@@EVENT/GET_BY_CODE",
  GET_BY_CODE_SUCCESS = "@@EVENT/GET_BY_CODE_SUCCESS",
  GET_BY_CODE_FAILURE = "@@EVENT/GET_BY_CODE_FAILURE",

  CREATE = "@@EVENT/CREATE",
  CREATE_SUCCESS = "@@EVENT/CREATE_SUCCESS",
  CREATE_FAILURE = "@@EVENT/CREATE_FAILURE",

  UPDATE = "@@EVENT/UPDATE",
  UPDATE_SUCCESS = "@@EVENT/UPDATE_SUCCESS",
  UPDATE_FAILURE = "@@EVENT/UPDATE_FAILURE",

  CLEAR_EVENT = "@@EVENT/CLEAR_EVENT",

  UPDATE_ROOMS = "@EVENT/UPDATE_ROOMS",
  UPDATE_ROOMS_SUCCESS = "@EVENT/UPDATE_ROOMS_SUCCESS",
  UPDATE_ROOMS_FAILURE = "@EVENT/UPDATE_ROOMS_FAILURE",

  EXIT_ROOM = "@EVENT/EXIT_ROOM",
  EXIT_ROOM_SUCCESS = "@EVENT/EXIT_ROOM_SUCCESS",
  EXIT_ROOM_FAILURE = "@EVENT/EXIT_ROOM_FAILURE",

  JOIN_EVENT = "@EVENT/JOIN_EVENT",
  JOIN_EVENT_SUCCESS = "@EVENT/JOIN_EVENT_SUCCESS",
  JOIN_EVENT_FAILURE = "@EVENT/JOIN_EVENT_FAILURE",

  SET_ROOM_CODE = "@EVENT/SET_ROOM_CODE",

  GET_USER_MEDIA = "@EVENT/GET_USER_MEDIA",
  GET_USER_MEDIA_SUCCESS = "@EVENT/GET_USER_MEDIA_SUCCESS",
  GET_USER_MEDIA_FAILURE = "@EVENT/GET_USER_MEDIA_FAILURE",

  CONNECT_SOCKET = "@EVENT/CONNECT_SOCKET",
  CONNECT_SOCKET_SUCCESS = "@EVENT/CONNECT_SOCKET_SUCCESS",
  CONNECT_SOCKET_FAILURE = "@EVENT/CONNECT_SOCKET_FAILURE",

  SEND_DECISION_MAKER_EMAIL = "@EVENT/SEND_DECISION_MAKER_EMAIL",
  SEND_DECISION_MAKER_EMAIL_SUCCESS = "@EVENT/SEND_DECISION_MAKER_EMAIL_SUCCESS",
  SEND_DECISION_MAKER_EMAIL_FAILED = "@EVENT/SEND_DECISION_MAKER_EMAIL_FAILED",

  ADD_STREAM = "@EVENT/ADD_STREAM",
  REMOVE_STREAM = "@EVENT/REMOVE_STREAM",

  UPDATE_EVENT_SETTINGS = "@@EVENT/UPDATE_EVENT_SETTINGS",
  CLEAR_EVENT_SETTINGS = "@@EVENT/CLEAR_EVENT_SETTINGS",

  START_EVENT = "@@EVENT/START_EVENT",
  CONNECT_EVENT_SOCKET = "@@EVENT/CONNECT_EVENT_SOCKET",
  CLOSE_SOCKET = "@@EVENT/CLOSE_SOCKET",

  WS_EVENT_UPDATED = "@@EVENT/WS_EVENT_UPDATED",
}
