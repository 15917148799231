import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { StyledAltarLogo } from "../../../../../shared/components/StyledAltarLogo";
import { Button } from "../../../../../shared/components/Button";
import { updateEvent } from "../../../store/actions";
import { EEventStatus } from "../../../interface";
import { AlertDialog } from "../../../../../shared/components/AlertDialog";
import { getEvent, getEventSettings } from "../../../store/selectors";
import { isUserHost } from "../../../utils/event";
import { getUser } from "../../../../Auth/store/selectors";
import { isMatchedPath } from "../../../../../shared/utils/location";
import { Prompt } from "../../../../../shared/components";
import config from "../../../../../config";

import "./styles.scss";

const Header: React.FC = () => {
  const [moveToLobbyAlertOpened, setMoveToLobbyAlertOpened] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(getUser());
  const currentEvent = useSelector(getEvent());
  const { activeDashboardTab } = useSelector(getEventSettings());

  const isHost = useMemo(() => isUserHost(user, currentEvent), [
    currentEvent,
    user,
  ]);

  const showLobby =
    isHost &&
    isMatchedPath(history.location.pathname, [
      "/:eventCode/watchparty/:roomCode",
    ]);
  const showComplete =
    isHost &&
    isMatchedPath(history.location.pathname, ["/:eventCode/lobby/:roomCode"]);

  const closeMoveToLobby = useCallback(() => {
    setMoveToLobbyAlertOpened(false);
  }, []);

  const moveToLobby = useCallback(() => {
    if (currentEvent?.id) {
      dispatch(
        updateEvent.request({
          status: EEventStatus.lobby,
          id: currentEvent.id,
        })
      );
    }
    closeMoveToLobby();
  }, [closeMoveToLobby, dispatch, currentEvent]);

  const signUpHandler = useCallback(() => {
    if (currentEvent?.id) {
      dispatch(
        updateEvent.request({
          status: EEventStatus.ended,
          id: currentEvent.id,
        })
      );
    }
  }, [dispatch, currentEvent]);

  const logoClickHandler = () => {
    const isDecisionPage = isMatchedPath(history.location.pathname, [
      "/decision",
      "/ended",
      "/full",
    ]);
    if (!isDecisionPage) {
      return;
    }
    window.open(config.webSiteUrl, "_self");
  };

  const promptText = (
    <>
      <p>Want to see</p>
      <p>the Lobby feature?</p>
    </>
  );

  return (
    <header className="eventHeader">
      <AlertDialog
        open={moveToLobbyAlertOpened}
        title="Move Everyone to Lobby"
        message="Are you sure you want to move everyone to the lobby?"
        onConfirm={moveToLobby}
        onCancel={closeMoveToLobby}
        mode="confirm"
        confirmText="yes"
        cancelText="cancel"
        variant="brown"
      />
      <div className="eventHeader-left">
        <div className="eventHeader-logo" onClick={logoClickHandler}>
          <img src="/icons/logo.svg" alt="logo" className="logo-mobile" />
          <StyledAltarLogo
            fill="#C9C0C6"
            width={112}
            height={32}
            className="logo"
          />
        </div>
      </div>
      <div className="eventHeader-right">
        <nav className="eventHeader-nav-items">
          {showLobby && (
            <Button
              onClick={() => setMoveToLobbyAlertOpened(true)}
              variant="orange"
              width={168}
              className="go-to-lobby"
            >
              <span>Go To Lobby</span>
              <img src="/icons/menu.svg" alt="go to lobby" />
              <Prompt
                className="goLobby-prompt"
                text={promptText}
                arrowVariant="top_right"
                showDelay={1000}
                hide={!!activeDashboardTab}
              />
            </Button>
          )}
          {showComplete && (
            <Button
              onClick={signUpHandler}
              variant="orange"
              width={168}
              className="complete"
            >
              <span>Finish Demo</span>
              <img src="/icons/check.svg" alt="complete" />
            </Button>
          )}
        </nav>
      </div>
    </header>
  );
};
export default Header;
