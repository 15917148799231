import React from "react";
import { useSnackbar } from "notistack";
import "./notistackActionButton.scss";

interface INotificationActionProps {
  snackKey: number | string | undefined;
}

const NotistackActionButton: React.FunctionComponent<INotificationActionProps> = ({
  snackKey,
}) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <div
      className="notificationActionButton"
      onClick={() => closeSnackbar(snackKey)}
    >
      <img alt="Close" src="/icons/notification_close.svg" />
    </div>
  );
};

export default NotistackActionButton;
