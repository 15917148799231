import { VariantType } from "notistack";

export enum NotificationActionTypes {
  REMOVE_SNACKBAR = "@@snackbar/REMOVE_SNACKBAR",
  ENQUEUE_SNACKBAR = "@@snackbar/ENQUEUE_SNACKBAR",
}

export const NotificationVariants: VariantType[] = [
  "success",
  "error",
  "warning",
  "info",
];
