import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";

import "./index.scss";

interface VideoMenuInterface {
  stream: MediaStream | null;
}

function isEnabled(aggregator: boolean, track: MediaStreamTrack) {
  return aggregator && track.enabled;
}

export const VideoMenu: React.FC<VideoMenuInterface> = ({ stream }) => {
  const [hasAudio, setAudio] = useState<boolean>(
    stream ? stream.getAudioTracks().reduce(isEnabled, true) : true
  );
  const [hasVideo, setVideo] = useState<boolean>(
    stream ? stream.getVideoTracks().reduce(isEnabled, true) : true
  );

  React.useEffect(() => {
    if (!stream) {
      return;
    }

    stream.getAudioTracks().forEach(function (track) {
      track.enabled = hasAudio;
    });
  }, [stream, hasAudio]);

  React.useEffect(() => {
    if (!stream) {
      return;
    }

    stream.getVideoTracks().forEach(function (track) {
      track.enabled = hasVideo;
    });
  }, [stream, hasVideo]);

  const handleToggleAudio = () => {
    setAudio((v) => !v);
  };

  const handleToggleVideo = () => {
    setVideo((v) => !v);
  };

  return (
    <div className="video-menu-container">
      <div
        className={`circle-button ${hasAudio ? "" : "disable"}`}
        onClick={() => handleToggleAudio()}
      >
        <img src="/icons/disable-micro.svg" alt="disalble-micro" />
      </div>
      <div
        className={`circle-button ${hasVideo ? "" : "disable"}`}
        onClick={() => handleToggleVideo()}
      >
        <img src="/icons/disable-video.svg" alt="disalble-video" />
      </div>
      <Tooltip title={"This is not available during the demo"} placement="top">
        <div className="circle-button end-call">
          <img src="/icons/end-call.svg" alt="end-call" />
        </div>
      </Tooltip>
    </div>
  );
};
