import React from "react";

import { Header } from "../../components/EventContainer";
import { Blocker } from "../../components/FullRoomContainer";

import "./styles.scss";

const EndedContainer: React.FC = () => (
  <div className="ended">
    <Header />
    <Blocker
      title="Sorry, the demo has been ended"
      description={
        <>
          The creator has ended the demo.
          <br />
          Click below to start your own demo
        </>
      }
    />
  </div>
);

export default EndedContainer;
