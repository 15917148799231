import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { getConnections, getEvent } from "../../../store/selectors";
import { isMatchedPath } from "../../../../../shared/utils/location";
import { Button } from "../../../../../shared/components/Button";
import { VideoMenu } from "../../StreamContainer";
import { getUser } from "../../../../Auth/store/selectors";
import { isUserHost } from "../../../utils/event";
import { EventBadge } from "../EventBadge";

import "./styles.scss";
import { updateEventSettings } from "../../../store/actions";
import { EventDashboardTabs } from "../../../interface";

const Footer: React.FC = () => {
  const { streams, stream } = useSelector(getConnections());
  const user = useSelector(getUser());
  const event = useSelector(getEvent());

  const history = useHistory();

  const dispatch = useDispatch();

  const isHost = useMemo(() => isUserHost(user, event), [event, user]);

  const showSignUp =
    isHost &&
    isMatchedPath(history.location.pathname, [
      "/:eventCode/watchparty/:roomCode",
      "/:eventCode/lobby",
      "/:eventCode/lobby/:roomCode",
    ]);

  const showControls = isMatchedPath(history.location.pathname, [
    "/:eventCode/watchparty/:roomCode",
    "/:eventCode/lobby/:roomCode",
  ]);

  const signUpHandler = useCallback(() => {
    history.push("/decision");
  }, [history]);

  const clickMembersCountBadgeHandler = useCallback(() => {
    dispatch(
      updateEventSettings({ activeDashboardTab: EventDashboardTabs.members })
    );
  }, [dispatch]);

  return (
    <div className={classnames("eventFooter")}>
      <div className="eventFooter-left">
        <EventBadge
          text={`${streams.length > 0 ? "34" : "33"} Members`}
          iconSrc="/icons/people_icon_small.svg"
          className="members-qty"
          onClick={clickMembersCountBadgeHandler}
        />
      </div>
      {showControls && (
        <div className="eventFooter-center">
          <VideoMenu stream={stream} />
        </div>
      )}
      <div className="eventFooter-right">
        {showSignUp && (
          <Button onClick={signUpHandler} width={154} className="sign-up">
            Sign up now
          </Button>
        )}
      </div>
    </div>
  );
};

export default Footer;
