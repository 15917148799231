import React from "react";
import { IconBase } from "../IconBase";
import { IStyledSVG } from "../../../interfaces";

export const StyledPolygon: React.FC<IStyledSVG> = ({
  style = {},
  fill = "#7B9EB9",
  width = "88",
  height = "200",
  className = "",
  opacity = 0.1,
}) => (
  <IconBase
    fill="none"
    width={width}
    style={style}
    height={height}
    className={className}
    opacity={opacity}
    viewBox="0 0 88 200"
  >
    <path d="M0 200H42.0418V41.8678H88V0H0.399661L0 200Z" fill={fill} />
  </IconBase>
);

export default StyledPolygon;
