import { NotificationActionTypes } from "./constants";
import { INotifications } from "../interfaces";
import { Reducer } from "redux";
import produce from "immer";

const initialState: INotifications = {
  snackbars: [],
};

const reducer: Reducer = (state: INotifications = initialState, action) => {
  switch (action.type) {
    case NotificationActionTypes.ENQUEUE_SNACKBAR:
      return produce(state, (nextState) => {
        nextState.snackbars = [...state.snackbars, { ...action.payload }];
      });
    case NotificationActionTypes.REMOVE_SNACKBAR:
      return produce(state, (nextState) => {
        nextState.snackbars = [...state.snackbars].filter(
          (snackbar) => snackbar.key !== action.payload
        );
      });
    default:
      return state;
  }
};

export { reducer as NotificationReducer };
