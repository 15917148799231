import * as React from "react";
import styled from "styled-components";

const VideoComponent = styled.video`
  width: 100%;
  vertical-align: bottom;
`;

type Props = {
  muted?: HTMLVideoElement["muted"];
  width?: HTMLVideoElement["width"];
  height?: HTMLVideoElement["height"];
  srcObject: any;
};

export const Video: React.FC<Props> = ({
  width,
  height,
  srcObject,
  ...rest
}) => {
  const videoRef = React.createRef<HTMLVideoElement>();

  React.useEffect(() => {
    const video = videoRef?.current;

    if (video && srcObject !== null) {
      video.srcObject = srcObject;
      if (video.readyState > 2) {
        video.play();
      }
    }
  }, [srcObject, videoRef]);

  return (
    srcObject && (
      <VideoComponent
        src={srcObject}
        ref={videoRef}
        playsInline
        autoPlay
        {...rest}
      />
    )
  );
};
