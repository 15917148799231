import { IMember } from "../../containers/Member/interface";

export const generateBackground = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
};

export const getInitials = (member: IMember) => {
  return member.first_name && member.last_name
    ? `${member?.first_name[0]}${member?.last_name[0]}`
    : "";
};
