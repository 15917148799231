import React from "react";
import Timer from "react-compound-timer";

import "./styles.scss";

type Props = {
  initTime: number;
  onTimeHasCome: () => void;
  short?: boolean;
};

const CountdownTimer: React.FC<Props> = ({
  initTime,
  onTimeHasCome,
  short,
}) => {
  return (
    <div className="countdown-timer">
      <Timer
        initialTime={initTime > 0 ? initTime : 0}
        direction="backward"
        formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
      >
        {({ getTime }: { [key: string]: Function }) => {
          const countdownTime = getTime();
          if (countdownTime < 1000) {
            onTimeHasCome();
          }
          return countdownTime < 24 * 60 * 60 * 1000 ? (
            <>
              <Timer.Hours />:
              <Timer.Minutes />
              {!short && ":"}
              {!short && <Timer.Seconds />}
            </>
          ) : (
            <>
              <Timer.Days
                formatValue={(v) => `${v} Day${v === 1 ? "" : "s"}`}
              />
              <Timer.Hours
                formatValue={(v) =>
                  v ? `, ${v} Hour${v === 1 ? "" : "s"}` : ""
                }
              />
              <Timer.Minutes
                formatValue={(v) =>
                  v ? `, ${v} Minute${v === 1 ? "" : "s"}` : ""
                }
              />
            </>
          );
        }}
      </Timer>
    </div>
  );
};

export default CountdownTimer;
