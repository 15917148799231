import React from "react";
import { useSelector } from "react-redux";

import { IMember } from "../../../../Member/interface";
import { getInitials } from "../../../../../shared/utils/userAvatarhelpers";
import { Prompt } from "../../../../../shared/components/Prompt";
import { getEventSettings } from "../../../store/selectors";

import "./styles.scss";

type Props = {
  attendee: IMember | null;
  onClick?: () => void;
  className?: string;
  available?: boolean;
};

const Seat: React.FC<Props> = ({ attendee, onClick, className, available }) => {
  const { activeDashboardTab } = useSelector(getEventSettings());

  return (
    <div
      className={`seat ${className ? className : ""} ${
        attendee ? "active" : ""
      }`}
    >
      <div className="seat-wrapper" onClick={onClick ? onClick : undefined}>
        {attendee ? (
          <>
            {attendee.image_url ? (
              <img
                src={attendee.image_url}
                alt={`${attendee.first_name} ${attendee.last_name}`}
              />
            ) : (
              <div className="initials">
                <div className="fallback">{getInitials(attendee)}</div>
              </div>
            )}
          </>
        ) : null}
      </div>
      <div className="seat-half-circle" />
      {available && (
        <Prompt
          className="seat-prompt"
          text="Select this seat"
          arrowVariant="bottom_left"
          showDelay={1000}
          hide={!!activeDashboardTab}
        />
      )}
    </div>
  );
};

export default Seat;
