import React, { useCallback, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { IStreamEventMember } from "../../../interface";

import "./styles.scss";

interface IEventMemberListItem {
  member: IStreamEventMember;
  own?: boolean;
}

const EventMemberListItem: React.FC<IEventMemberListItem> = ({
  member,
  own,
}) => {
  const { image_url } = member;
  const [error, setError] = useState(false);

  const fullName = `${member.first_name} ${member.last_name}`;

  const initials = useCallback(() => {
    if (!fullName) return "";
    const initialsArray = fullName.toUpperCase().split(" ").filter(Boolean);
    const [firstName = " ", lastName = " "] = initialsArray;
    return firstName[0] + lastName[0];
  }, [fullName]);

  return (
    <div className="eventMemberListItem">
      <div className="eventMemberListItem-data">
        <div className="eventMemberListItem-image">
          {image_url && !error ? (
            <img
              src={image_url}
              alt={initials()}
              onError={() => setError(true)}
            />
          ) : (
            <div className={"eventMemberListItem-image-fallback"}>
              {initials()}
            </div>
          )}
        </div>
        <div className="eventMemberListItem-name  ">{fullName}</div>
      </div>
      {!own && (
        <Tooltip
          title={"This is not available during the demo"}
          placement="top"
        >
          <div className="eventMemberListItem-btn">
            <hr />
            <hr />
            <hr />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
export default EventMemberListItem;
